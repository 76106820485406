import type { ModalTypeKeys } from './InterviewProcessModal';

export const FirstStageHeader = () => (
  <div className="interview_stage">
    <hr />
    <p className="title uppercase">
      1<sup>st</sup> Stage
    </p>
    <p className="body">via Zoom</p>
  </div>
);

export const FinalStageHeader = () => (
  <div className="interview_stage">
    <hr />
    <p className="title uppercase">Final Stage</p>
    <p className="body">Face-to-face</p>
  </div>
);

export const DepthOfExpertiseInterviewStage = () => (
  <div className="interview_stage">
    <p className="title">Depth of Expertise</p>
    <p className="body">
      A technical conversation, focused on a project you enjoy talking about in
      depth to demonstrate a good example of your work.
    </p>
  </div>
);

export const CultureInterviewStage = () => (
  <div className="interview_stage">
    <p className="title">Culture</p>
    <p className="body">
      A competency-based, non-technical interview exploring how you approach
      your work in line with core Skyscanner values, as well as your motivations
      and drivers.
    </p>
  </div>
);

export const SystemDesignInterviewStage = ({
  double = false,
}: {
  double?: boolean;
}) => (
  <div className="interview_stage">
    <p className="title">System Design{double ? ' (x2)' : ''}</p>
    <p className="body">
      A whiteboarding system design interview, looking at a real-world problem
      that can be found at Skyscanner. This looks at how you would solve the
      problem, but also how you would get that solution into production.{' '}
      {double ? 'Each ' : 'This interview '} lasts 45-60 mins.
    </p>
  </div>
);

export const InterviewPrepButton = ({
  modalType,
  onSetModalType,
  stage,
}: {
  modalType: ModalTypeKeys;
  onSetModalType: (newModalType: ModalTypeKeys) => void;
  stage: '1' | 'Final';
}) => (
  <button
    className="interview-stage-btn"
    onClick={() => onSetModalType(modalType)}
    type="button"
  >
    Prep for your {stage}
    {stage === '1' && <sup>st</sup>} Stage Interview
  </button>
);

export const InterviewNote = () => (
  <p className="interview-note">
    *If you have a disability or require any special arrangements at any stage
    of our interview process, then please let us know as soon as you can.
  </p>
);
