import BpkLargeCityCenterIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/city-center';
import BpkLargeFoodIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/food';
import BpkLargeHeartIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/heart';

import JobList from '../../components/JobList';
import JobsAppHero from '../../components/JobsAppHero';

const SingaporePage = () => (
  <>
    <JobsAppHero
      hideHero={false}
      bannerType="singapore"
      hideHeroMobile={false}
    />
    <section className="grid__container">
      <div className="grid__row">
        <div className="grid__column--12 grid__column--mobile-12">
          <h1 className="offices-header">Singapore</h1>
          <div className="offices-body">
            <p>
              Tropical climate, abundant greenery, award-winning food and drink,
              exhilarating entertainment, beaches, bays, shops galore and a
              brilliantly diverse international community. It’s no wonder we
              chose Singapore as our home in Southeast Asia.
            </p>
            <p>
              Since opening in 2013, we’ve grown to an energetic, talented team
              of over 40 people across multiple business areas. Interested in
              joining us? Check out our live roles.
            </p>
          </div>
        </div>
      </div>
      <hr aria-hidden="true" />
    </section>

    <section className="grid__container">
      <div className="grid__row content-block">
        <div className="grid__column--4 grid__column--tablet-12">
          <BpkLargeCityCenterIcon className="city-center-icon--large" />
          <br />
          <h2 id="life-section2-col1-header">Where we are</h2>
          <div id="life-section2-col1-body">
            <p>
              Our team is based in a stylish, beautifully designed co-working
              space in the Central Business District, near the famous Raffles
              Hotel.
            </p>
            <p>
              We’re centrally located so there’s no shortage of restaurants and
              cafes to chose from. Fancy some shopping? You’ll be spoilt for
              choice with designer brands, high-street favourites and
              independent markets close by.
            </p>
          </div>
        </div>
        <div className="grid__column--4 grid__column--tablet-12">
          <BpkLargeHeartIcon className="heart-icon--blue" />
          <h2 id="life-section2-col2-header">Our culture</h2>
          <div id="life-section2-col2-body">
            <p>
              Our international team passionately promotes diversity,
              inclusivity and openness. While lunches, dinners, team sports and
              get-togethers are a regular occurrence.
            </p>
            <p>
              We celebrate global occasions and special dates in style,
              including themed events for International Women’s Day and Pride
              Month and parties for Chinese New Year and Christmas.
            </p>
          </div>
        </div>
        <div className="grid__column--4 grid__column--tablet-12">
          <BpkLargeFoodIcon className="food-icon" />
          <h2 id="life-section2-col3-header">Things to do</h2>
          <div id="life-section2-col3-body">
            <p>
              Marvel at the colossal Supertrees at Gardens By the Bay, discover
              quirky street art at Haji Lane or live the high life at Marina Bay
              Sands.
            </p>
            <p>
              If you’re after something more active, sweat it out at Singapore
              Sports Hub, visit the thrilling Skyline Luge Sentosa or don your
              hiking boots for the stunning Coast-to-Coast Trail.
            </p>
          </div>
        </div>
      </div>
    </section>
    <JobList location="Singapore" />
  </>
);

export default SingaporePage;
