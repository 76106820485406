import { useState } from 'react';

import JobList from '../../components/JobList';

const GraduatesPage = () => {
  const [jobCount, setJobCount] = useState(0);

  return (
    <div>
      <div className="grid__container">
        <div className="grid__row content-block" style={{ paddingBottom: 0 }}>
          <div className="grid__column--6 grid__column--mobile-12">
            <p className="page-sub-title">Early careers</p>
            <h1 className="teams-header">Graduates</h1>
            <a
              href="#job-list-section"
              className={`button${jobCount === 0 ? ' button--disabled' : ''}`}
              id="explore-jobs-button"
            >
              Explore jobs
            </a>
          </div>
          <div className="grid__column--6">
            <img
              alt="graduate caps"
              className="early-careers-header-svg ls-is-cached lazyloaded"
              src="https://images.skyscnr.com/sttc/jobssite/svg/graduate-caps-throwing.e3a64abf55e32eb6cf62.svg"
            />
          </div>
        </div>
      </div>
      <section
        className="grid__container--full-width tab-content"
        aria-live="polite"
      >
        <article id="engineer-content">
          <div className="grid__container">
            <div className="grid__row" style={{ paddingBottom: '3rem' }}>
              <div className="grid__column--12 grid__column--tablet-12">
                <p>
                  <strong>Make an impact from day one.</strong>
                </p>
                <p>
                  Our programmes have been carefully designed to provide
                  Graduates with engaging development opportunities, as well as
                  a mentor who comes with a wealth of experience and knowledge.
                </p>
                <p>
                  The majority of our roles are in Engineering with some
                  occasional positions across Finance, Business Analytics,
                  Marketing, Commercial and People.
                </p>
                <p>
                  Your programme starts from day one with a blend of learning
                  both on and off the job.
                </p>
                <p>
                  Skyscanner is The Real Deal. Our people are The Real Deal.
                  It’s why we continue to thrive, together. You’ll be supported
                  by an experienced line manager, a technical buddy and the
                  Early Careers Team, as well as being surrounded by colleagues
                  you can share with and learn from. You’ll work on the same
                  challenging problems and projects as the rest of your team (we
                  call them squads), and see your work having a direct impact on
                  travellers. We encourage you to give us your all, and in
                  return we’ll support you in achieving your ambitions.
                </p>
                <p>
                  Our Engineering Graduate Programme gives you the opportunity
                  to gain experience and exposure to different technologies
                  through regular rotations. Once the programme is finished,
                  we’ll work with you to find your home at Skyscanner based on
                  your skills and interests. We work with our graduates
                  individually to shape a career pathway prior to the programme
                  finishing to ensure your journey with us continues to go from
                  strength to strength.
                </p>
                <p>We hire Graduates in Scotland, Barcelona and Shenzhen.</p>
              </div>
            </div>
            <hr aria-hidden="true" />
          </div>
        </article>
      </section>
      <section className="grid__container">
        <div className="grid__row center-row content-block">
          <div className="grid__column--5 grid__column--tablet-12 video-container--portrait">
            <video
              className="video"
              controls
              src="https://content.skyscnr.com/m/28c7fb6f663b0a3c/original/Skyscanner-Matthew-Social-9x16-lockup-subs.mp4"
            >
              <track
                src="../../transcripts/Skyscanner-Matthew-Social-9x16-lockup-subs-en-US.vtt"
                kind="captions"
                label="English"
              />
            </video>
          </div>
          <div className="grid__column--6 grid__column--tablet-12">
            <div className="skyscanner-quote">
              <p className="quote">
                “My favourite part of the culture at Skyscanner is the ability
                to truly be yourself and be accepted. It’s something everyone
                takes seriously.”
              </p>
              <p className="sub-heading">
                Matthew Reynolds, Graduate Software Engineer
              </p>
            </div>
          </div>
        </div>
        <hr aria-hidden="true" />
      </section>
      <section
        className="grid__container--full-width tab-content"
        aria-live="polite"
      >
        <div className="grid__container">
          <div className="grid__row" style={{ paddingBottom: '3rem' }}>
            <div className="grid__column--12 grid__column--tablet-12  personal-development">
              <p>
                <strong>
                  Personal development with all the support you need
                </strong>
              </p>
              <ul
                className="space-after-text"
                style={{
                  marginTop: 0,
                  paddingLeft: '2rem',
                }}
              >
                <li className="list-item">
                  You’ll be supported by an experienced line manager during each
                  rotation
                </li>
                <li className="list-item">
                  You’ll be assigned a buddy from day one to help you navigate
                  life at Skyscanner
                </li>
                <li className="list-item">
                  Through each rotation you’ll also be assigned a technical
                  buddy who’s there to help you understand the new squad
                </li>
                <li className="list-item">
                  As part of your development programme, the Early Careers team
                  will check in regularly to see how you’re getting on and make
                  sure you’re feeling happy and on track with your personal
                  progress
                </li>
                <li className="list-item">
                  There will also be Graduate Ambassadors you can speak to, and
                  who’ll work with the Early Careers Team to improve the
                  Graduate Programme
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <JobList
        department="Graduate"
        setJobCountCallback={(count) => setJobCount(count)}
      />
    </div>
  );
};

export default GraduatesPage;
