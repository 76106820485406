import BpkLargeCityCenterIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/city-center';
import BpkLargeFoodIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/food';
import BpkLargeHeartIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/heart';

import JobList from '../../components/JobList';
import JobsAppHero from '../../components/JobsAppHero';

const TokyoPage = () => (
  <>
    <JobsAppHero hideHero={false} bannerType="tokyo" hideHeroMobile={false} />
    <section className="grid__container">
      <div className="grid__row">
        <div className="grid__column--12 grid__column--mobile-12">
          <h1 className="offices-header">Tokyo</h1>
          <div className="offices-body">
            <p>
              Bright lights, cool culture, futuristic tech, ancient tradition,
              stunning parks, incredible food, endless entertainment. There’s a
              reason everyone raves about Tokyo and many reasons we chose this
              city as a base to grow our Japanese team.
            </p>
            <p>
              Our Tokyo office opened in 2015 and now is home to a close-knit
              team with a high concentration in sales and marketing. The main
              language spoken here is Japanese, however, the office is extremely
              multi-cultural and everyone speaks English.
            </p>
          </div>
        </div>
      </div>
      <hr aria-hidden="true" />
    </section>

    <section className="grid__container">
      <div className="grid__row content-block">
        <div className="grid__column--4 grid__column--tablet-12">
          <BpkLargeCityCenterIcon className="city-center-icon--large" />
          <br />
          <h2 id="life-section2-col1-header">Where we are</h2>
          <div id="life-section2-col1-body">
            <p>
              We’re based in a brand-new co-working space in central Tokyo, at
              the very heart of the action. Close to the Omotesando street of
              high fashion, the beautiful Yoyogi Park and the famous Shibuya
              Scramble Square, we’re never stuck for choice when it comes to
              food, entertainment, shopping or simply to explore.
            </p>
            <p>
              It’s also easy and cheap to access other parts of the city, such
              as the Harajuku, Roppongi and Ginza districts.
            </p>
          </div>
        </div>
        <div className="grid__column--4 grid__column--tablet-12">
          <BpkLargeHeartIcon className="heart-icon--blue" />
          <h2 id="life-section2-col2-header">Our culture</h2>
          <div id="life-section2-col2-body">
            <p>
              Our culture here is hugely respectful, friendly and supportive. We
              truly value our co-workers, their skills, their strengths and
              their needs.
            </p>
            <p>
              Like our other offices, we’re big on flexibility &mdash; whether
              that’s being able to work from different locations or having time
              to help with family or childcare needs. We also love a party and
              celebrate our successes at monthly socials.
            </p>
          </div>
        </div>
        <div className="grid__column--4 grid__column--tablet-12">
          <BpkLargeFoodIcon className="food-icon" />
          <h2 id="life-section2-col3-header">Things to do</h2>
          <div id="life-section2-col3-body">
            <p>
              Tokyo has an endless number of things to see and do, with
              delicious Japanese and international food, sightseeing, people
              watching, shopping, shrines, parks and neon lights in abundance.
            </p>
            <p>
              Once you’ve experienced legendary karaoke bars, world-class
              cinemas, huge game centres and galleries, take a break from the
              city and visit the surrounding national parks or the magnificent
              Mount Fuji.
            </p>
          </div>
        </div>
      </div>
    </section>
    <JobList location="Tokyo" />
  </>
);

export default TokyoPage;
