const DropdownItemArrow = ({
  down,
  title,
}: {
  down: boolean;
  title: string;
}) => (
  <svg
    className={`dropdown-item__arrow ${
      down ? 'dropdown-item__arrow--rotate' : ''
    }`}
    viewBox="0 0 256 512"
    role="img"
  >
    <title>{title}</title>
    <path
      className="st0"
      d="M224.3,273l-136,136c-9.4,9.4-24.6,9.4-33.9,0l-22.6-22.6c-9.4-9.4-9.4-24.6,0-33.9l96.4-96.4l-96.4-96.4
	c-9.4-9.4-9.4-24.6,0-33.9L54.3,103c9.4-9.4,24.6-9.4,33.9,0l136,136C233.7,248.4,233.7,263.6,224.3,273z"
    />
  </svg>
);

export default DropdownItemArrow;
