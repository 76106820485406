import { useState } from 'react';

import JobList from '../../components/JobList';

const EngineeringPage = () => {
  const [jobCount, setJobCount] = useState(0);

  return (
    <div>
      <section className="grid__container">
        <div className="grid__row content-block">
          <div className="grid__column--12 grid__column--mobile-12">
            <p className="page-sub-title">Build without limits</p>
            <h1 className="teams-header">Engineering</h1>
            <a
              href="#job-list-section"
              className={`button${jobCount === 0 ? ' button--disabled' : ''}`}
              id="explore-jobs-button"
            >
              Explore jobs
            </a>
          </div>
        </div>
      </section>
      <section className="grid__container">
        <div className="video-container--landscape">
          <video
            className="video"
            controls
            src="https://content.skyscnr.com/m/718b145afe1be3fa/original/Jobs-site-Engineering-Video.mp4"
            width="100%"
          >
            <track
              src="../../transcripts/Skyscanner-Engineering-subs-V0.3-en-US.vtt"
              kind="captions"
              label="English"
            />
          </video>
        </div>
      </section>
      <section className="grid__container">
        <div className="grid__row content-block">
          <div className="grid__column--12 grid__column--mobile-12">
            <p id="teams-engineering-body">
              Engineering at Skyscanner is the best of both worlds &mdash;
              enormous scale combined with the opportunity to own and make an
              impact on products used by more than 110 million people every
              month.
              <br />
              <br />
              Our output is huge and inner workings complex. Getting the right
              flights, hotels or cars to each individual traveller from hundreds
              of thousands of options in a few seconds is challenging, but our
              engineers deliver and improve our product every day to create a
              seamless and smooth experience.
              <br />
              <br />
              But you won’t just be a small cog in a large machine. Our
              engineers have the scope and opportunity to make a difference from
              day one &mdash; developing and maintaining traveller- and
              partner-facing products as well as custom internal tools and
              platforms.
              <br />
              <br />
              You’ll own the whole lifecycle and see the impact your work has on
              travellers across the globe. And while our teams work
              autonomously, we’re always collaborating with other stakeholders
              and colleagues, locally and globally, to understand and validate
              the best ways to solve traveller, partner and Skyscanner
              challenges.
              <br />
              <br />
              Skyscanner is The Real Deal. Our people are The Real Deal. It’s
              why we continue to thrive, together. We trust our people to make
              the right decisions and you’ll have significant control in
              determining how to best solve problems. You won’t find any
              micromanagement at Skyscanner, or any tech-bro clichés &mdash;
              just the support from a diverse group who’ll help you grow your
              career in exciting, rewarding ways.
            </p>
          </div>
        </div>
        <hr aria-hidden="true" />
      </section>
      <section className="grid__container">
        <div className="grid__row center-row content-block">
          <div className="grid__column--5 grid__column--tablet-12 video-container--portrait">
            <video
              className="video"
              controls
              src="https://content.skyscnr.com/m/52662e5d4af7fcfe/original/Skyscanner-Amber-Social-9x16-lockup-subs-V2.mp4"
            >
              <track
                src="../../transcripts/Skyscanner-Amber-Social-9x16-lockup-subs V2-en-US.vtt"
                kind="captions"
                label="English"
              />
            </video>
          </div>
          <div className="grid__column--7 grid__column--tablet-12">
            <div className="skyscanner-quote">
              <p className="quote">
                “The people are genuinely so lovely, incredibly smart, and
                inclusive. You’ll have the opportunity to make a real impact and
                build genuine connections.”
              </p>
              <p className="sub-heading">Amber Shand, Software Engineer</p>
            </div>
          </div>
        </div>
      </section>
      <JobList
        department="Engineering"
        setJobCountCallback={(count) => setJobCount(count)}
      />
    </div>
  );
};

export default EngineeringPage;
