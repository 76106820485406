import { useState } from 'react';

import JobList from '../../components/JobList';

const InternshipPage = () => {
  const [jobCount, setJobCount] = useState(0);

  return (
    <div>
      <div className="grid__container">
        <div className="grid__row content-block" style={{ paddingBottom: '0' }}>
          <div className="grid__column--6 grid__column--mobile-12">
            <p className="page-sub-title">Early careers</p>
            <h1 className="teams-header">Internships</h1>
            <a
              href="#job-list-section"
              className={`button${jobCount === 0 ? ' button--disabled' : ''}`}
              id="explore-jobs-button"
            >
              Explore jobs
            </a>
          </div>
          <div className="grid__column--6">
            <img
              alt="two people"
              className="early-careers-header-svg ls-is-cached lazyloaded"
              src="https://images.skyscnr.com/sttc/jobssite/svg/luggage-4.f887183fdf0ac38e6d76.svg"
            />
          </div>
        </div>
      </div>
      <section
        className="grid__container--full-width tab-content"
        aria-live="polite"
      >
        <article id="engineer-content">
          <div className="grid__container">
            <div className="grid__row" style={{ paddingBottom: '3rem' }}>
              <div className="grid__column--12 grid__column--tablet-12">
                <p>
                  <strong>Internships at Skyscanner</strong>
                </p>
                <p>
                  Our internships are based in Scotland and, as an intern at
                  Skyscanner, you’ll be treated like everybody else. We’ll give
                  you an awesome experience where you’ll be a valuable and
                  contributing member to your tribe and squad. You’ll benefit
                  from on-the-job, online and classroom-based learning.
                </p>
                <p>
                  You’ll work hard in your area of the business, learn new
                  skills and be celebrated for what makes you unique to keep
                  Skyscanner dynamic and competitive in an exciting and
                  ever-changing environment.
                </p>
                <p>
                  You can also win an internship with us through the
                  &ldquo;Software Engineer Undergraduate of the Year&rdquo;
                  competition. Click the link below to find out more.
                </p>
                <p>
                  Forage hosts our virtual internship. It’s a unique opportunity
                  for you to take on practical tasks similar to what our own
                  Skyscanner engineers would work on and get a true feel of what
                  it’s like to work here as a front-end, back-end, mobile, and
                  product engineer.
                </p>
              </div>
            </div>
          </div>
        </article>
      </section>
      <div className="grid__background--blue mobile-logo-container mobile-logo-container--white">
        <section className="grid__container">
          <div className="grid__row content-block">
            <div className="link-body link-body--left content-block grid__column--12">
              <a
                className="link-body__logo win-internship-logo content-block grid__column--12"
                href="https://undergraduateoftheyear.com/software-engineering"
                aria-label="Find out more about the Undergraduate of the year awards"
              />
              <h2 className="win-internship-text">Win a paid internship</h2>
              <p className="win-internship-text">
                We’re proud to be part of Undergraduate of the Year 2024. Are
                you passionate about technology? Enter the Software Engineering
                Undergraduate of the Year competition and you could win a paid
                Skyscanner Internship in Edinburgh, plus exclusive one-to-one
                networking opportunities with senior colleagues.
              </p>
              <a
                href="https://undergraduateoftheyear.com/software-engineering"
                className="button button--white"
                aria-label="Find out more about the Undergraduate of the year awards"
              >
                Find out more
              </a>
            </div>
          </div>
        </section>
      </div>
      <div className="grid__background--gray mobile-logo-container mobile-logo-container">
        <section className="grid__container">
          <div className="grid__row content-block">
            <div className="link-body link-body--right content-block grid__column--12">
              <a
                className="link-body__logo forage-logo content-block grid__column--12"
                href="https://www.theforage.com/virtual-internships/prototype/p3xGFkpdot5H8NBih/Software-Engineering"
                aria-label="Find out more about Skyscanner's software engineering virtual experience programme"
              />
              <h2>
                Skyscanner’s Software Engineering virtual experience programme
              </h2>
              <p>
                A unique opportunity for you to take on practical tasks similar
                to what our own Skyscanner engineers would work on and get a
                true feel of what it’s like to work here as a front-end,
                back-end, mobile, and product engineer.
              </p>
              <a
                href="https://www.theforage.com/virtual-internships/prototype/p3xGFkpdot5H8NBih/Software-Engineering"
                className="button"
                aria-label="Find out more about Skyscanner's software engineering virtual experience programme"
              >
                Find out more
              </a>
            </div>
          </div>
        </section>
      </div>
      <div className="grid__background--blue-and-gray">
        <section className="grid__container">
          <div className="grid__row center-row">
            <div className="links-row">
              <div className="link-body link-body--left content-block grid__column--6">
                <a
                  className="link-body__logo win-internship-logo content-block grid__column--12"
                  href="https://undergraduateoftheyear.com/software-engineering"
                  aria-label="Find out more about the Undergraduate of the year awards"
                />
                <h2 className="win-internship-text">Win a paid internship</h2>
                <p className="win-internship-text">
                  We’re proud to be part of Undergraduate of the Year. Are you
                  passionate about technology? Enter the Software Engineering
                  Undergraduate of the Year competition and you could win a paid
                  Skyscanner Internship in Edinburgh, plus exclusive one-to-one
                  networking opportunities with senior colleagues.
                </p>
                <a
                  href="https://undergraduateoftheyear.com/software-engineering"
                  className="button button--white"
                  aria-label="Find out more about the Undergraduate of the year awards"
                >
                  Find out more
                </a>
              </div>
              <div className="link-body link-body--right content-block grid__column--6">
                <a
                  className="link-body__logo forage-logo content-block grid__column--12"
                  href="https://www.theforage.com/virtual-internships/prototype/p3xGFkpdot5H8NBih/Software-Engineering"
                  aria-label="Find out more about Skyscanner's software engineering virtual experience programme"
                />
                <h2>
                  Skyscanner’s Software Engineering virtual experience programme
                </h2>
                <p>
                  A unique opportunity for you to take on practical tasks
                  similar to what our own Skyscanner engineers would work on and
                  get a true feel of what it’s like to work here as a front-end,
                  back-end, mobile, and product engineer.
                </p>
                <a
                  href="https://www.theforage.com/virtual-internships/prototype/p3xGFkpdot5H8NBih/Software-Engineering"
                  className="button"
                  aria-label="Find out more about Skyscanner's software engineering virtual experience programme"
                >
                  Find out more
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section className="grid__container">
        <div className="grid__row center-row content-block">
          <div className="grid__column--7 desktop-only">
            <div className="skyscanner-quote">
              <p className="quote">
                “Interning at Skyscanner has been a brilliant opportunity to
                really get a feel of what it’s like to be a software engineer in
                the industry. Everyone has been so supportive and the presence
                of such a wide variety of folks from different backgrounds and
                experiences has helped me improve my own skills through seeing
                everyone’s unique approaches to coding.”
              </p>
              <p className="sub-heading">
                Leah Hughes, Summer Intern, 2022 (Undergraduate of the Year
                Winner 2022)
              </p>
            </div>
          </div>
          <div className="grid__column--5 grid__column--tablet-12 photo-container--right">
            <img
              className="photo"
              alt="Leah Hughes, Summer Intern, 2022 (Undergraduate of the Year Winner 2022)"
              src="https://images.skyscnr.com/sttc/jobssite/img/leah.ec3f05580dde96e5997f.jpg"
            />
          </div>
          <div className="grid__column--tablet-12 mobile-and-tablet-only">
            <div className="skyscanner-quote">
              <p className="quote">
                “Interning at Skyscanner has been a brilliant opportunity to
                really get a feel of what it’s like to be a software engineer in
                the industry. Everyone has been so supportive and the presence
                of such a wide variety of folks from different backgrounds and
                experiences has helped me improve my own skills through seeing
                everyone’s unique approaches to coding.”
              </p>
              <p className="sub-heading">
                Leah Hughes, Summer Intern, 2022 (Undergraduate of the Year
                Winner 2022)
              </p>
            </div>
          </div>
        </div>
      </section>
      <JobList
        department="Internships"
        setJobCountCallback={(count) => setJobCount(count)}
      />
    </div>
  );
};

export default InternshipPage;
