import { useState } from 'react';

import loadable from '@loadable/component';

const IntersectionObserverWrapper = loadable(
  () =>
    import('../../../IntersectionObserverWrapper/IntersectionObserverWrapper'),
);

const OurCulture = () => {
  const [showContent, setShowContent] = useState(false);

  return (
    <IntersectionObserverWrapper
      threshold={0.4}
      onElementSeen={() => setShowContent(true)}
    >
      <OurCultureContent showContent={showContent} />
    </IntersectionObserverWrapper>
  );
};

export default OurCulture;

export const OurCultureContent = ({
  showContent,
}: {
  showContent: boolean;
}) => (
  <section className="our-culture">
    <div
      className={`culture-image-container image-container ${
        showContent ? 'greyscale-in' : 'greyscaled'
      }`}
    />
    <div className="culture-content-container section-padding">
      <div className="culture-title-row row">
        <h2>Our culture</h2>
      </div>
      <div className="culture-content-row row">
        <div className="culture-paragraph">
          <p>
            We draw a lot of parallels between our team members and our
            travellers. Probably because they&rsquo;re often the same people. It
            helps to be united by a love of travel and it keeps us committed to
            doing the right thing for travellers and the planet.
          </p>
          <p>
            We believe that great people make great products. So we spend time
            and money on developing individuals who make what we do possible,
            who are also obsessed with making travel easier and better for
            everyone. We&rsquo;re proud of the positive culture and community
            we&rsquo;ve created as a place where everyone can grow.
          </p>
        </div>
        <div className="culture-figures">
          <p className="culture-figures-descriptor">
            <span className="culture-figures-number">1,500</span>
            <br />
            employees
          </p>
          <p className="culture-figures-descriptor">
            <span className="culture-figures-number">50</span>
            <br />
            nationalities
          </p>
        </div>
      </div>
    </div>
  </section>
);
