import { useState } from 'react';

import JobList from '../../components/JobList';

const MarketingPage = () => {
  const [jobCount, setJobCount] = useState(0);

  return (
    <div>
      <section className="grid__container">
        <div className="grid__row content-block">
          <div className="grid__column--12 grid__column--mobile-12">
            <p className="page-sub-title">Share our story</p>
            <h1 className="teams-header">Marketing</h1>
            <a
              href="#job-list-section"
              className={`button${jobCount === 0 ? ' button--disabled' : ''}`}
              id="explore-jobs-button"
            >
              Explore jobs
            </a>
            <div className=" content-block">
              <p id="teams-engineering-body">
                Marketing’s mission is to grow our audience in a sustainable and
                authentic way, building stronger relationships with travellers
                and delivering engaging, exciting, relevant content that echoes
                and enhances the joy of travel.
                <br />
                <br />
                As travel’s a very personal experience, it’s essential we
                deliver messaging that resonates with each individual traveller,
                whether they’re a foodie, adrenaline junkie, culture sponge,
                luxury lover or art aficionado.
                <br />
                <br />
                Skyscanner is The Real Deal. Our people are The Real Deal. It’s
                why we continue to thrive, together. Marketing is spread across
                a variety of different teams, areas of expertise and
                disciplines. Whether you’re best suited to Brand Marketing,
                Lifecycle Marketing, Geo Growth, Affiliates, Performance,
                AdTech, Paid Marketing or SEO, you’ll be given opportunities to
                contribute and collaborate from day one to raise our quality of
                work and make an immediate impact. The sky’s the limit!
              </p>
            </div>
          </div>
        </div>
        <hr aria-hidden="true" />
      </section>
      <section className="grid__container">
        <div className="grid__row center-row content-block">
          <div className="grid__column--5 grid__column--tablet-12 photo-container">
            <img
              className="photo"
              alt="Alicia Yau, Market Development Manager"
              src="https://content.skyscnr.com/m/40373fe451862772/Medium-Marketing_Image_421x331.png"
            />
          </div>
          <div className="grid__column--7 grid__column--tablet-12">
            <div className="skyscanner-quote">
              <p className="quote">
                “I believe it is very important having a cadence in your life
                and getting up every morning to go do something (whether it’s
                paid work or volunteering) that feels worthwhile, mentally
                rewarding and brings a sense of contribution to others around
                us.”
              </p>
              <p className="sub-heading">
                Alicia Yau, Market Development Manager
              </p>
            </div>
          </div>
        </div>
      </section>
      <JobList
        department="Marketing"
        setJobCountCallback={(count) => setJobCount(count)}
      />
    </div>
  );
};

export default MarketingPage;
