import BpkLargeCityCenterIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/city-center';
import BpkLargeFoodIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/food';
import BpkLargeHeartIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/heart';

import JobList from '../../components/JobList';
import JobsAppHero from '../../components/JobsAppHero';

const BarcelonaPage = () => (
  <>
    <JobsAppHero
      hideHero={false}
      bannerType="barcelona"
      hideHeroMobile={false}
    />
    <section className="grid__container">
      <div className="grid__row">
        <div className="grid__column--12 grid__column--mobile-12">
          <h1 className="offices-header">Barcelona</h1>
          <div className="offices-body">
            <p>
              Mind-blowing architecture, sandy beaches, amazing cuisine, vibrant
              nightlife, sunshine, culture, sports, shopping and markets galore.
              Barcelona is a bustling, beautiful, thoroughly international city.
              It’s also one of Europe’s fastest-growing tech hubs offering a
              great work/life balance, Mediterranean city lifestyle and access
              to world-class talent.
            </p>
            <p>
              We opened our Spanish office in 2014 and our team here currently
              stands at 140 with new and exciting opportunities on the horizon.
              The main language spoken in the office is English, however most
              people also speak Spanish.
            </p>
          </div>
        </div>
      </div>
      <hr aria-hidden="true" />
    </section>
    <section className="grid__container">
      <div className="grid__row content-block">
        <div className="grid__column--4 grid__column--tablet-12">
          <BpkLargeCityCenterIcon className="city-center-icon--large" />
          <br />
          <h2 id="life-section2-col1-header">Where we are</h2>
          <div id="life-section2-col1-body">
            <p>
              Our location couldn’t be more central. Looking out onto Plaza
              Catalunya at the end of Las Ramblas (with the best transport
              connections in the city), our open-plan office is close to plenty
              of shops and cafes.
            </p>
            <p>
              We’re also right on the edge of the Gothic Quarter, with parks
              within a short stroll and the city beach just a 25-minute walk
              away.
            </p>
          </div>
        </div>
        <div className="grid__column--4 grid__column--tablet-12">
          <BpkLargeHeartIcon className="heart-icon--blue" />
          <h2 id="life-section2-col2-header">Our culture</h2>
          <div id="life-section2-col2-body">
            <p>
              Work/life balance is extremely important to us. We look out for
              each other and are passionate about maintaining a healthy, happy,
              supportive environment.
            </p>
            <p>
              Our team are wonderfully open, friendly and collaborative &mdash;
              as a new starter, you won’t feel new for long. We eat together
              often and, in the summer, head up to our roof terrace for
              after-work drinks.
            </p>
          </div>
        </div>
        <div className="grid__column--4 grid__column--tablet-12">
          <BpkLargeFoodIcon className="food-icon" />
          <h2 id="life-section2-col3-header">Things to do</h2>
          <div id="life-section2-col3-body">
            <p>
              Barcelona is a city that lives and breathes art, culture and
              sport. After you’ve experienced Gaudi’s magnificent La Sagrada
              Familia, Park Guell and beyond, there’s high-end cuisine, tasty
              street food, word-class football, live music, serene parks and
              lush countryside to explore.
            </p>
          </div>
        </div>
      </div>
    </section>
    <JobList location="Barcelona" />
  </>
);

export default BarcelonaPage;
