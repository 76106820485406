import { useState } from 'react';

import JobList from '../../components/JobList';

const BusinessPage = () => {
  const [jobCount, setJobCount] = useState(0);

  return (
    <div>
      <section className="grid__container">
        <div className="grid__row content-block">
          <div className="grid__column--12 grid__column--mobile-12">
            <p className="page-sub-title">Own the ‘how’</p>
            <h1 className="teams-header">Business</h1>
            <a
              href="#job-list-section"
              className={`button${jobCount === 0 ? ' button--disabled' : ''}`}
              id="explore-jobs-button"
            >
              Explore jobs
            </a>
            <div className=" content-block">
              <p id="teams-engineering-body">
                Our business teams cover Operations, Strategy, Finance, Legal,
                Commercial, and Talent, supporting the wider organisation to
                deliver effectively and intelligently against our strategy to
                help us reach our goals and beyond.
                <br />
                <br />
                We’re here to empower teams across Skyscanner in executing on
                our ambitious strategy, which will see us serve more and more
                travellers as the industry continues to grow. We’re also here to
                make sure we do things in the right way, bettering our company
                culture through diversity, equity and inclusion, and bringing
                our values to life.
                <br />
                <br />
                Skyscanner is The Real Deal. Our people are The Real Deal. It’s
                why we continue to thrive, together. The opportunities for
                development, learning and cross-company impact are huge. You’ll
                also often find yourself responding to new and ever-changing
                global and local challenges.
                <br />
                <br />
                From strategising around geo-political and world health issues
                to helping deliver and improve our long-term sustainability
                strategy, there’s endless opportunity to help Skyscanner grow
                and thrive, in a rewarding environment that’s built to support
                your own growth and ambition.
              </p>
            </div>
          </div>
        </div>
        <hr aria-hidden="true" />
      </section>
      <section className="grid__container">
        <div className="grid__row center-row content-block">
          <div className="grid__column--5 grid__column--tablet-12 photo-container">
            <img
              className="photo"
              alt="Craig Latham, Senior Talent Acquisition Executive"
              src="https://content.skyscnr.com/m/2b858fff54a57866/Medium-BusOps_Image_421x331.png"
            />
          </div>
          <div className="grid__column--7 grid__column--tablet-12">
            <div className="skyscanner-quote">
              <p className="quote">
                “Everyone is there to help, always. No matter the question, our
                team is very collaborative. But they give you the space to make
                mistakes and learn and grow from those experiences.”
              </p>
              <p className="sub-heading">
                Craig Latham, Senior Talent Acquisition Executive
              </p>
            </div>
          </div>
        </div>
      </section>
      <JobList
        department="Business"
        setJobCountCallback={(count) => setJobCount(count)}
      />
    </div>
  );
};

export default BusinessPage;
