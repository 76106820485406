const ExecVideo = () => (
  <div className="exec-video-container">
    <video className="exec-video-container" controls playsInline loop>
      <source
        src="https://content.skyscnr.com/m/1e71d38f853fe8d5/original/Skyscanner-Myra-Hero-16x9-lockup-subs-V2.mp4"
        type="video/mp4"
      />
      <track
        src="../../transcripts/Skyscanner-Myra-Hero-16x9-lockup-subs-V2-en-US.vtt"
        kind="captions"
        label="English"
      />
    </video>
  </div>
);

export default ExecVideo;
