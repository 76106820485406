import { useState } from 'react';

import BpkInput, {
  INPUT_TYPES,
  CLEAR_BUTTON_MODES,
} from '@skyscanner/backpack-web/bpk-component-input';
import BpkSelect from '@skyscanner/backpack-web/bpk-component-select';

import JobList from '../components/JobList';
import JobsAppHero from '../components/JobsAppHero';

const JobsPage = () => {
  const [location, setLocation] = useState('All');
  const [department, setDepartment] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');
  return (
    <>
      <JobsAppHero bannerType="jobs" hideHeroMobile />
      <div className="grid__container">
        <div className="grid__row">
          <div className="grid__column--12 grid__column--tablet-12">
            <h1 className="search-header">Current jobs</h1>
            <div className="search-form">
              <div className="search-form__select-container search-form__departments-container">
                <BpkSelect
                  id="department-filter"
                  name="department-filter"
                  value={department}
                  onChange={(e: React.FormEvent<HTMLInputElement>) =>
                    setDepartment(e.currentTarget.value)
                  }
                  title="Departmnet Filter"
                  aria-label="Department Filter"
                >
                  <option value="All">All teams</option>
                  <option value="Business">Business</option>
                  <option value="Design">Design</option>
                  <option value="Engineering">Engineering</option>
                  <option value="Graduate">Graduate</option>
                  <option value="Intern">Intern</option>
                  <option value="Marketing">Marketing</option>
                  <option value="Product">Product</option>
                </BpkSelect>
              </div>
              <div className="search-form__select-container search-form__offices-container">
                <BpkSelect
                  id="location-filter"
                  name="location-filter"
                  value={location}
                  onChange={(e: React.FormEvent<HTMLInputElement>) =>
                    setLocation(e.currentTarget.value)
                  }
                  title="Location Filter"
                  aria-label="Location Filter"
                >
                  <option value="All">All locations</option>
                  <option value="Barcelona">Barcelona</option>
                  <option value="Edinburgh">Edinburgh</option>
                  <option value="Glasgow">Glasgow</option>
                  <option value="Gurugram (Delhi NCR)">
                    Gurugram (Delhi NCR)
                  </option>
                  <option value="London">London</option>
                  <option value="Miami">Miami</option>
                  <option value="Shenzhen">Shenzhen</option>
                  <option value="Singapore">Singapore</option>
                  <option value="Tokyo">Tokyo</option>
                </BpkSelect>
              </div>
              <div className="search-form__input-container">
                <BpkInput
                  id="search-form__input"
                  type={INPUT_TYPES.text}
                  name="input"
                  height="100%"
                  style={{ height: '100%' }}
                  onChange={(e: React.FormEvent<HTMLInputElement>) =>
                    setSearchTerm(e.currentTarget.value)
                  }
                  value={searchTerm}
                  placeholder="Search by title, team or location"
                  clearButtonMode={CLEAR_BUTTON_MODES.whileEditing}
                  clearButtonLabel="Clear"
                  onClear={() => setSearchTerm('')}
                  title="Search by title, team or location"
                  aria-label="Search by title, team or location"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <JobList
        location={location}
        department={department}
        searchTerm={searchTerm}
        hideTitle
        contrastBackground={false}
      />
    </>
  );
};

export default JobsPage;
