const JobsAppSubFooter = () => (
  <div className="grid__container--full-width">
    <nav className="sub-footer" aria-label="subfooter links">
      <div className="grid__container sub-footer__links">
        <ul className="footer-links internal">
          <li>
            <a href="https://www.skyscanner.net/">Main website</a>
          </li>
          <li>
            <a href="https://help.skyscanner.net/hc/en-gb" rel="noopener">
              Customer Services
            </a>
          </li>
          <li>
            <a href="https://www.skyscanner.net/blogs ">Skyscanner Blogs</a>
          </li>
          <li>
            <a href="https://www.skyscanner.net/mobile.html">Mobile Apps</a>
          </li>
        </ul>
        <ul className="footer-links social">
          <li>
            <a
              href="http://www.twitter.com/skyscanner"
              title="Skyscanner on Twitter"
            >
              <svg
                className="icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                role="img"
              >
                <title>Twitter logo</title>
                <path
                  role="presentation"
                  d="M18.8065909,6.53464255 C19.5608603,6.0359196 20.1403208,5.24634859 20.4131758,4.30546195 C19.7070835,4.76726427 18.925119,5.10259384 18.0927511,5.28317102 C17.4262869,4.50017894 16.4766377,4.01088254 15.4256475,4.01088254 C13.407732,4.01088254 11.7715819,5.81478866 11.7715819,8.03994336 C11.7715819,8.35563426 11.8039078,8.66317517 11.8662441,8.95814735 C8.82939709,8.7900407 6.13691369,7.18605582 4.33477092,4.74841119 C4.0202397,5.3434616 3.83999871,6.03552684 3.83999871,6.77393923 C3.83999871,8.17181671 4.48509048,9.4050243 5.46555161,10.1275294 C4.86658871,10.1066143 4.30315751,9.92534975 3.81052254,9.62360226 C3.81016633,9.64039328 3.81016633,9.65728254 3.81016633,9.67426994 C3.81016633,11.6264477 5.06971603,13.2547845 6.74130876,13.6250708 C6.43470315,13.7170778 6.11189011,13.7663707 5.778658,13.7663707 C5.54320485,13.7663707 5.31425248,13.7410369 5.09108848,13.6941006 C5.55611737,15.2946487 6.90552041,16.4597102 8.50453585,16.492114 C7.25398039,17.5728277 5.67847464,18.2168764 3.96645237,18.2168764 C3.67151257,18.2168764 3.38066915,18.197827 3.09481263,18.1605136 C4.71190561,19.3037763 6.63257642,19.9709005 8.69608643,19.9709005 C15.4170985,19.9709005 19.0925365,13.8313746 19.0925365,8.50704811 C19.0925365,8.33246067 19.0888854,8.15865884 19.0818503,7.9858389 C19.7958682,7.41779163 20.415313,6.70814982 20.9051874,5.90021669 C20.2499437,6.22062089 19.5456324,6.43723496 18.8065909,6.53464255 Z"
                />
              </svg>
              <span>Twitter</span>
            </a>
          </li>
          <li>
            <a
              href="http://www.facebook.com/skyscanner"
              title="Skyscanner on Facebook"
            >
              <svg
                className="icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                role="img"
              >
                <title>Facebook logo</title>
                <path
                  role="presentation"
                  d="M13.0205264,8.08956668 L13.0205264,10.0769074 L15.6447369,10.0769074 L15.6447369,13.0579186 L13.0205264,13.0579186 L13.0205264,20.0136113 L10.3963158,20.0136113 L10.3963158,13.0579186 L8.35526309,13.0579186 L8.35526309,10.0769074 L10.3963158,10.0769074 L10.3963158,8.08956668 C10.3963158,6.99775055 10.70949,6.06236032 11.335848,5.28336792 C11.9622059,4.50437551 12.7181437,4.11488516 13.6036843,4.11488516 L15.6447369,4.11488516 L15.6447369,7.0958963 L13.6036843,7.0958963 C13.4416951,7.0958963 13.3040065,7.1940356 13.1906141,7.39031715 C13.0772217,7.5865987 13.0205264,7.81967955 13.0205264,8.08956668 C13.0205264,8.08956668 13.0205264,7.81967955 13.0205264,8.08956668 L13.0205264,8.08956668 L13.0205264,8.08956668 Z"
                />
              </svg>
              <span>Facebook</span>
            </a>
          </li>
          <li>
            <a
              href="https://www.tiktok.com/@skyscanner"
              title="Skyscanner on TikTok"
            >
              <svg
                className="icon"
                width="21"
                height="24"
                viewBox="0 0 20 28"
                role="img"
              >
                <title>TikTok logo</title>
                <path
                  role="presentation"
                  d="M 15.285156 0 C 15.6875 3.40625 17.628906 5.4375 21 5.652344 L 21 9.484375 C 19.046875 9.671875 17.335938 9.042969 15.34375 7.863281 L 15.34375 15.027344 C 15.34375 24.128906 5.222656 26.972656 1.152344 20.449219 C -1.460938 16.253906 0.140625 8.886719 8.527344 8.589844 L 8.527344 12.628906 C 7.890625 12.730469 7.207031 12.890625 6.582031 13.097656 C 4.714844 13.71875 3.65625 14.875 3.953125 16.921875 C 4.515625 20.839844 11.847656 22 11.238281 14.34375 L 11.238281 0.0078125 L 15.285156 0.0078125 Z M 15.285156 0 "
                />
              </svg>
              <span>TikTok</span>
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/user/SkyscannerLtd"
              title="Skyscanner on YouTube"
            >
              <svg
                className="icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                role="img"
              >
                <title>YouTube logo</title>
                <path
                  role="presentation"
                  d="M11.9687502,4.64646812 C16.5763167,4.64646812 18.5629135,4.94972528 18.5629135,4.94972528 C20.0894433,5.08898515 21.505034,6.45187075 21.7167833,7.96823781 C21.7167833,7.96823781 21.9498424,8.5637709 21.9498426,11.9497762 C21.9498426,15.2527902 21.7194169,15.8430345 21.7194169,15.8430345 C21.5284307,17.3671845 20.095763,18.7394317 18.5690885,18.9106264 C18.5690885,18.9106264 16.6212791,19.2530842 11.9687502,19.2530842 C7.25665213,19.2530842 5.37700228,18.9134909 5.37700228,18.9134909 C3.84144956,18.7536175 2.43059443,17.3691166 2.21653576,15.849942 C2.21653576,15.849942 1.98658861,15.2879278 1.98658881,11.9497762 C1.98658881,8.59890851 2.22005211,7.98316335 2.22005211,7.98316335 C2.41042584,6.45558923 3.84274495,5.09854921 5.37066223,4.94550956 C5.37066223,4.94550956 7.21168981,4.64646863 11.9687502,4.64646812 Z M9.17173305,8.44528653 L9.17173305,15.5370631 L15.6403276,11.9911748 L9.17173305,8.44528653 Z"
                />
              </svg>
              <span>YouTube</span>
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/123380"
              title="Skyscanner on LinkedIn"
            >
              <svg
                className="icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                role="img"
              >
                <title>LinkedIn logo</title>
                <path
                  role="presentation"
                  d="M7.72866119,9.48694135 L7.72866119,19.8742505 L4.27660882,19.8742505 L4.27660882,9.48694135 L7.72866119,9.48694135 Z M10.111965,19.8742505 L10.111965,11.8324628 L10.0558206,9.48694135 L13.3683396,9.48694135 L13.4806284,10.9203156 C14.2666537,9.74134386 15.2772426,9.15186686 16.5124253,9.15186686 C17.7226549,9.15186686 18.6989337,9.56450074 19.4412909,10.3897809 C20.1836482,11.2150611 20.5548213,12.3660925 20.5548213,13.8429097 L20.5548213,19.8742505 L17.186158,19.8742505 L17.186158,14.1779842 C17.186158,13.3837296 17.0177265,12.7942525 16.6808585,12.4095355 C16.3439905,12.0248184 15.9010781,11.8324628 15.352108,11.8324628 C14.8156144,11.8324628 14.3695829,12.0062033 14.0140001,12.3536898 C13.6584171,12.7011761 13.4806284,13.1975778 13.4806284,13.8429097 L13.4806284,19.8742505 L10.111965,19.8742505 Z M5.98345694,7.81156889 L5.96427887,7.81156889 C5.36336304,7.81156889 4.87432718,7.63472579 4.49715661,7.28103428 C4.11998604,6.92734277 3.93140359,6.48988879 3.93140359,5.9686592 C3.93140359,5.43501938 4.12318236,4.99446288 4.50674565,4.64697649 C4.89030894,4.2994901 5.39532636,4.12574951 6.02181308,4.12574951 C6.63551434,4.12574951 7.12774652,4.2994901 7.49852437,4.64697649 C7.86930222,4.99446288 8.06108099,5.43501938 8.07386643,5.9686592 C8.07386643,6.48988879 7.88208766,6.92734277 7.49852437,7.28103428 C7.11496108,7.63472579 6.60994365,7.81156889 5.98345694,7.81156889 Z"
                />
              </svg>
              <span>LinkedIn</span>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
);

export default JobsAppSubFooter;
