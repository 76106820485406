interface Props {
  bannerType: string;
  hideHero?: boolean;
  hideHeroMobile?: boolean;
}

const JobsAppHero = ({
  bannerType,
  hideHero = false,
  hideHeroMobile = false,
}: Props) => {
  const bannerName = `${bannerType}-banner`;
  const heroClassName = `hero ${bannerName} ${
    hideHeroMobile ? 'desktop-only' : ''
  }`;
  return (
    <div className={heroClassName}>
      {bannerType === 'home' && (
        <div className="grid__container">
          <h1 id="index-section1-header" className="home__heading">
            A world of opportunity.
            <br />
            One incredible team.
          </h1>
          <a
            href="/jobs/current-jobs/"
            className="button"
            aria-label="Explore all jobs"
          >
            Explore all jobs
          </a>
        </div>
      )}
      <div className="flare-bar__container">
        <svg
          role="presentation"
          viewBox="0 0 14832 55"
          className="flare-bar__curve"
        >
          <path
            role="presentation"
            d="M7501.307 8.517l-68.043 39.341c-10.632 6.185-23.795 6.185-34.528 0l-68.144-39.34c-8.91-5.173-18.988-8.215-29.316-8.518H0v55h14832V0H7530.671a63.604 63.604 0 0 0-29.364 8.517z"
          />
        </svg>
      </div>
    </div>
  );
};

export default JobsAppHero;
