import JobsAppHero from '../components/JobsAppHero';

const HomePage = () => (
  <>
    <JobsAppHero bannerType="home" />
    <div className="grid__container">
      <div className="grid__row content-block">
        <div className="header-container">
          <div className="skyscanner-quote">
            <p id="index-section1-body" className="sub-heading">
              Travel is so much more than moving from one place to another. It’s
              about discovery, excitement, adventure, meeting new people,
              experiencing new cultures and breaking from the norm. It’s about
              journeys and destinations that make your eyes light up and neurons
              fire.
            </p>
            <p id="index-section1-body-2" className="sub-heading">
              At Skyscanner, we fully embrace the joy of travel and strive to
              bring this ethos of discovery, community and culture into what we
              do every day. In how we operate as a company, how we treat our
              people and how we deliver for our travellers.
            </p>
            <p id="index-section1-body-3" className="sub-heading">
              Skyscanner is The Real Deal. Our people are The Real Deal. It’s
              why we continue to thrive, together. We’re always on the lookout
              for brilliant, inquisitive minds to join our team. Think that’s
              you? We’d love to hear from you.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="grid__background--gray">
      <div className="grid__container home__section">
        <div className="grid__row center-row content-block">
          <div className="grid__column--5 grid__column--tablet-12 photo-container">
            <img
              className="photo"
              alt="Two people playing ping-pong"
              src="https://content.skyscnr.com/m/616b66a8f4e27af7/Medium-1-Jobs_Image_421x331.png"
            />
          </div>
          <div className="grid__column--7 grid__column--tablet-12">
            <div className="skyscanner-quote">
              <h2 id="index-section2-header">
                There are over 100 million reasons to work here
              </h2>
              <p id="index-section2-body">
                And we’re obsessed with helping them all find their next trip.
                It’s what’s made us trailblazers in travel for over 20 years.
                <br />
                <br />
                From Engineering, Product and Business to Marketing and Design,
                we’ve never been interested in doing the same old thing. We
                thrive on new ideas and fresh perspectives and aren’t afraid to
                fail a few times to achieve great things in the long run.
              </p>
              <a
                href="https://www.skyscanner.net/about-us"
                className="button"
                aria-label="Our teams"
              >
                Our mission
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="grid__container home__section">
      <div className="grid__row center-row content-block">
        <div className="grid__column--5 grid__column--tablet-12 photo-container--left">
          <img
            className="photo mobile-and-tablet-only"
            alt="Three people playing a game console"
            src="https://content.skyscnr.com/m/7eda967d9574928b/Medium-2-Jobs_Image_421x331.png"
          />
        </div>
        <div className="grid__column--7 grid__column--tablet-12">
          <div className="skyscanner-quote">
            <h2>
              We love to WFB*
              <br />
              *Work from Barcelona
            </h2>
            <p>
              With offices in eight locations worldwide, we’re proud of the
              friendliness and the open attitude of our culture and people
              across our international operation.
            </p>
            <p>
              Fancy a change of scenery? You can work from anywhere in the world
              for up to 20 days of the year. We also give you the opportunity to
              spend up to 30 working days within a 24-month period working from
              a different Skyscanner office of your choice.
            </p>
            <a
              href="/jobs/offices/"
              className="button"
              aria-label="Our locations"
            >
              See our locations
            </a>
          </div>
        </div>
        <div className="grid__column--5 grid__column--tablet-12 photo-container--right">
          <img
            className="photo desktop-only"
            alt="Three people playing a game console"
            src="https://content.skyscnr.com/m/7eda967d9574928b/Medium-2-Jobs_Image_421x331.png"
          />
        </div>
      </div>
    </div>

    <div className="grid__background--gray">
      <div className="grid__container home__section">
        <div className="grid__row center-row content-block">
          <div className="grid__column--5 grid__column--tablet-12 photo-container">
            <img
              className="photo"
              alt="Employees collaborating at Skyscanner"
              src="https://content.skyscnr.com/m/32172bb4f9904e15/Medium-3-Jobs_Image_421x331.png"
            />
          </div>
          <div className="grid__column--7 grid__column--tablet-12">
            <div className="skyscanner-quote">
              <h2>No more Sunday scaries</h2>
              <p>
                We look forward to Mondays. Honestly. From the very beginning,
                we’ve always had a people-first approach &mdash; both with our
                travellers and our teams. We take our social, ethical and
                environmental responsibilities seriously, working to protect the
                future of travel for generations to come.
              </p>
              <p>
                We also believe that we’ll succeed and grow together. Our
                flexible reward package includes lots of exciting benefits to
                help you feel fulfilled both inside and outside of work
                including support for our own wellbeing. We create an
                environment where you can do your best work.
              </p>
              <a
                href="/jobs/life/"
                className="button"
                aria-label="Life at Skyscanner"
              >
                Life at Skyscanner
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default HomePage;
