import BpkLargeCityCenterIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/city-center';
import BpkLargeFoodIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/food';
import BpkLargeHeartIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/heart';

import JobList from '../../components/JobList';
import JobsAppHero from '../../components/JobsAppHero';

const ShenzhenPage = () => (
  <>
    <JobsAppHero
      hideHero={false}
      bannerType="shenzhen"
      hideHeroMobile={false}
    />
    <section className="grid__container">
      <div className="grid__row">
        <div className="grid__column--12 grid__column--mobile-12">
          <h1 className="offices-header">Shenzhen</h1>
          <div className="offices-body">
            <p>
              Warm climate, ocean views, city parks, lush mountains, epic
              architecture, abundant culture and a world-leading tech and
              business scene. Shenzhen, aka ‘the city of the future’ and
              ‘China’s Silicon Valley’, has an astounding amount to offer.
            </p>
            <p>
              Since landing in Shenzhen in 2014, Skyscanner’s China team has
              flourished. We’re always eager to welcome, learn from and
              collaborate with more brilliant minds. Our engineering and product
              teams make up the majority of our 200-person workforce, but we
              regularly hire in other areas too. The main language spoken is
              Mandarin and most of our team also speak English.
            </p>
          </div>
        </div>
      </div>
      <hr aria-hidden="true" />
    </section>
    <section className="grid__container">
      <div className="grid__row content-block">
        <div className="grid__column--4 grid__column--tablet-12">
          <BpkLargeCityCenterIcon className="city-center-icon--large" />
          <br />
          <h2 id="life-section2-col1-header">Where we are</h2>
          <div id="life-section2-col1-body">
            <p>
              Our impressive oceanfront office is located in the
              ultra-prestigious, super-sleek One Shenzhen Bay tower.
            </p>
            <p>
              As well as stunning views over to nearby Hong Kong, we’re lucky to
              have some of the city’s best shops, restaurants and cafes on our
              doorstep. Want to stretch your legs at lunchtime? Head to
              Shenzhen’s glorious Talent Park or soak up the sea air on the
              exhilarating bayside running track.
            </p>
          </div>
        </div>
        <div className="grid__column--4 grid__column--tablet-12">
          <BpkLargeHeartIcon className="heart-icon--blue" />
          <h2 id="life-section2-col2-header">Our culture</h2>
          <div id="life-section2-col2-body">
            <p>
              We’re passionate about inclusion, innovation, flexibility and
              work/life balance, and do our utmost to cater for the diverse
              needs and interests of our talented team.
            </p>
            <p>
              As well as in-house yoga classes there are plenty of team sports
              and clubs to choose from, including badminton, basketball, games
              night, cartoon club and beyond. And, of course, delicious food and
              team celebrations.
            </p>
          </div>
        </div>
        <div className="grid__column--4 grid__column--tablet-12">
          <BpkLargeFoodIcon className="food-icon" />
          <h2 id="life-section2-col3-header">Things to do</h2>
          <div id="life-section2-col3-body">
            <p>
              With an exciting mix of people, world-famous design, gorgeous
              green spaces, stunning mountains and excellent shopping, there’s
              always something to do in Shenzhen.
            </p>
            <p>
              Hiking and outdoor activities are huge here. The city is
              surrounded by mountains and jam-packed with parks. We’re also home
              to some of China’s most loved theme parks, galleries and
              restaurants.
            </p>
          </div>
        </div>
      </div>
    </section>
    <JobList location="Shenzhen" />
  </>
);

export default ShenzhenPage;
