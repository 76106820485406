import BpkRightChevron from '@skyscanner/backpack-web/bpk-component-icon/lg/chevron-right';
import BpkCityCenterIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/city-center';
import {
  BpkLargeSpinner,
  SPINNER_TYPES,
} from '@skyscanner/backpack-web/bpk-component-spinner';

import { useGetJobs } from '../hooks/useGetJobs';

interface JobListProps {
  contrastBackground?: boolean;
  department?: string;
  hideTitle?: boolean;
  location?: string;
  searchTerm?: string;
  setJobCountCallback?: (jobCount: number) => void;
}

const JobList = ({
  contrastBackground = true,
  department = undefined,
  hideTitle = false,
  location = undefined,
  searchTerm = undefined,
  setJobCountCallback = undefined,
}: JobListProps) => {
  const { jobs, loading } = useGetJobs();

  const filteredJobs = jobs.filter((job) => {
    let result = true;
    if (location && location !== 'All') {
      result = job.location?.name.includes(location);
      if (!result) {
        return false;
      }
    }

    const jobMeta = job.metadata.find((meta) => meta.name === 'Business Area');
    const jobDepartment = jobMeta?.value;

    if (department && department !== 'All') {
      if (department === 'Early Careers') {
        return jobDepartment === 'Graduate' || jobDepartment === 'Intern';
      }
      return jobDepartment && jobDepartment === department;
    }

    if (searchTerm) {
      const title = job.title.toLowerCase();
      const lowerCaseTerm = searchTerm.toLowerCase();
      return (
        title.includes(lowerCaseTerm) ||
        jobDepartment?.toLowerCase().includes(lowerCaseTerm) ||
        job.location?.name.toLowerCase().includes(lowerCaseTerm)
      );
    }

    return result;
  });
  const jobCount = filteredJobs.length;

  if (!loading && setJobCountCallback) {
    setJobCountCallback(jobCount);
  }

  return (
    <div
      id="job-list-section"
      className={`current-jobs-container grid__container--full-width ${
        contrastBackground ? 'contrast-panel' : ''
      }`}
    >
      <div className="grid__container">
        <div className="grid__row">
          <div className="grid__column--12 job__container">
            {!hideTitle && (
              <h2 className="center job-list-title">
                {!loading && <span id="job-list-count">{jobCount}</span>}
                {!loading && (
                  <span
                    id="job-list-count-word"
                    className="job-list-count-word"
                  >{` job${jobCount !== 1 ? 's' : ''} in `}</span>
                )}
                <span id="job-list-count-area" data-testid="job-list-label">
                  {getLabel(location, department)}
                </span>
                <br />
              </h2>
            )}
            {!loading && jobCount === 0 && (
              <span className="job-list__button-container">
                <a
                  href="/jobs/current-jobs"
                  className="job-list__button"
                  id="job-list__button"
                >
                  See all jobs<span aria-hidden="true">{`>`}</span>
                </a>
              </span>
            )}
            <div id="job-list" className="job-list-container">
              {loading ? (
                <BpkLargeSpinner
                  type={SPINNER_TYPES.primary}
                  className="jobs-list-spinner"
                />
              ) : (
                filteredJobs.map((job) => (
                  <div className="job-listing" key={job.id}>
                    <a href={`/jobs/job/${job.id}`}>
                      <div>
                        <h2 className="job-title">{job.title}</h2>
                        <div className="job-department">
                          {
                            job.metadata.find(
                              (meta) => meta.name === 'Business Area',
                            )?.value
                          }
                        </div>
                        <p className="job-location">
                          <BpkCityCenterIcon className="city-center-icon" />
                          {job.location.name}
                        </p>
                      </div>
                      <span className="job-listing__icon-container">
                        <BpkRightChevron className="right-chevron-icon" />
                      </span>
                    </a>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const getLabel = (
  location: string | undefined,
  department: string | undefined,
) => {
  if (location && department) {
    return `${location} and ${department}`;
  }
  if (location) {
    return location;
  }
  return department;
};

export default JobList;
