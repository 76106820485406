import type { ReactNode } from 'react';
import { useState } from 'react';

import DropdownItemArrow from './DropdownItemArrow';

interface DropdownItemProps {
  title: string;
  children: ReactNode;
}

const DropdownItem = ({ children, title }: DropdownItemProps) => {
  const [showBody, setShowBody] = useState(false);

  return (
    <div className="dropdown-item">
      <button
        className="dropdown-title"
        aria-label={`${title} Button`}
        onClick={() => setShowBody((prev) => !prev)}
        type="button"
      >
        <span>{title}</span>
        <DropdownItemArrow down={showBody} title={`Expand ${title}`} />
      </button>

      {showBody && <div className="dropdown_body">{children}</div>}
    </div>
  );
};

export default DropdownItem;
