export const DepthOfExpertiseSection = () => (
  <>
    <h4>Technical session: Depth of Expertise (1 hour)</h4>
    <p>
      You&rsquo;ll be asked to describe your most complex or interesting
      project. Try to use a recent project relevant to Skyscanner in terms of a
      scaled web platform, with a global customer base, for example.
    </p>
    <p>
      Ideally, lead the interview with a verbal presentation, talking through
      elements of the project including low-level system design, business
      goals/impact measurement, best practice and your personal contribution.
      Also cover technical aspects such as scalability, complexities and
      operations.
    </p>
    <p>
      As much as you can, add context around the &lsquo;why&rsquo; rather than
      just the how. Give as much depth of knowledge and context on the project
      as possible.
    </p>
    <p>
      You don&rsquo;t need to prepare a presentation, but if you want to use
      visual aids to help the interviewer understand more context, feel free.
      You can familiarise yourself with Zoom and the white board option{' '}
      <a
        href="https://support.zoom.us/hc/en-us/articles/205677665-Share-a-Whiteboard"
        target="_blank"
        rel="noreferrer"
      >
        here
      </a>
      .
    </p>
  </>
);

export const CultureSection = ({
  upperLevelPosition = false,
}: {
  upperLevelPosition?: boolean;
}) => (
  <>
    <h4>Culture &ndash; (1 hour)</h4>
    <p>
      This competency-based interview will be run by someone that might not be
      from your discipline.
    </p>
    <p>The following will be assessed:</p>

    <p>
      <b>Leadership</b>
    </p>
    <p>
      We lead by example and influence positively for the whole business, not
      just ourselves. The interviewer will assess your influencing and
      collaboration skills and whether you can:
    </p>
    <ul>
      <li>Articulate a vision for winning and how to achieve it</li>
      <li>
        Broker workable solutions amidst differing opinions; can communicate to
        a diverse audience; and can control meetings and ensure successful
        outcomes
      </li>
      {upperLevelPosition ? (
        <li>
          Work on multiple growth initiatives in the same market or channel
        </li>
      ) : (
        <li>Reduce complexity, creating simplicity for the traveller</li>
      )}
      <li>Collaborate with everyone</li>
    </ul>

    <p>
      <b>Internet economy familiarity</b>
    </p>
    <p>
      We follow lean and agile principles and you&rsquo;ll need to as well. We
      strive for fresh thinking, autonomy, sharing and urgency by removing
      blockers quickly and helping teams adapt. You&rsquo;ll be asked about:
    </p>
    <ul>
      <li>
        <a
          href="https://en.wikipedia.org/wiki/Theory_of_constraints"
          target="_blank"
          rel="noreferrer"
        >
          TOC
        </a>
        ,{` `}
        <a
          href="https://en.wikipedia.org/wiki/Lean_software_development"
          target="_blank"
          rel="noreferrer"
        >
          Lean
        </a>
        {` `}and{` `}
        <a
          href="https://en.wikipedia.org/wiki/Agile_software_development"
          target="_blank"
          rel="noreferrer"
        >
          Agile
        </a>
        {` `}principles
      </li>
      <li>
        <a
          href="https://en.wikipedia.org/wiki/Situation,_task,_action,_result"
          target="_blank"
          rel="noreferrer"
        >
          Failing forward
        </a>
        {` `}and applying learnings
      </li>
      <li>Product sensibilities</li>
      <li>Software tool proficiency</li>
      <li>User focus</li>
    </ul>

    <p>
      <b>Motivation</b>
    </p>
    <p>
      The interviewer will love to hear more about your motivation and why you
      want to join Skyscanner.
    </p>
  </>
);

export const SystemDesignSection = ({
  double = false,
}: {
  double?: boolean;
}) => (
  <>
    <h4>System Design{double ? ' - (2x 1 hour)' : ''}</h4>
    <p>
      {double ? 'These' : 'This'} system design interview{double ? 's' : ''}{' '}
      will be led by engineers. For remote interviews, use the{` `}
      <a
        href="https://support.zoom.us/hc/en-us/articles/205677665-Share-a-Whiteboard"
        target="_blank"
        rel="noreferrer"
      >
        Zoom whiteboard tool
      </a>
      , or make an alternative arrangement that the interviewer can see clearly.
    </p>
    <p>
      As much you can, lead the session by talking the interviewer through the
      solution, using the whiteboard as an aid to illustrate your ideas. Ask
      plenty of questions to ensure you have the information you need to solve
      the problem.
    </p>
    <p>
      The interviewer will assess your clarity of thought and communication,
      problem solving and autonomy, system design, efficiency and scalability,
      operational considerations, technology selection, delivery and product and
      user focus.
    </p>

    <h4>Quick tips for the System Design interview{double ? 's' : ''}:</h4>
    <ul>
      <li>
        <b>Share your thought process with the interviewers:</b>
        {` `}
        Communication will be key to assess your problem-solving skills.
      </li>
      <li>
        <b>Be open to challenge:</b> Our technical stack is very large and
        interviewers will want to understand whether you can justify the
        decisions you make.
      </li>
      <li>
        <b>Use the whiteboard:</b> We can&rsquo;t stress this enough! It&rsquo;s
        there to help you so use it as much as you need.
      </li>
      <li>
        <b>Ask questions if you need more clarity:</b>
        {` `}
        Interviewers are always here to help.
      </li>
    </ul>
  </>
);

export const InterviewNoteSection = () => (
  <p className="interview-note">
    *If you have a disability or require any special arrangements at any stage
    of our interview process, then please let us know as soon as you can.
  </p>
);
