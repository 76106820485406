import ExecVideo from './ExecVideo';
import Faces from './Faces';
import HowWeMove from './HowWeMove';
import InterviewProcess from './InterviewProcess';
import OurBrand from './OurBrand';
import OurCulture from './OurCulture';
import PrepPackHero from './PrepPackHero';
import TheStorySoFar from './TheStorySoFar';
import '../../styles/keyframes.css';

const CandidatePrepPack = () => (
  <div className="candidate-prep-pack-container">
    <PrepPackHero />
    <TheStorySoFar />
    <OurBrand />
    <OurCulture />
    <ExecVideo />
    <Faces />
    <HowWeMove />
    <InterviewProcess />
  </div>
);

export default CandidatePrepPack;
