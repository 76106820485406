import { useState } from 'react';

import loadable from '@loadable/component';

const IntersectionObserverWrapper = loadable(
  () =>
    import('../../../IntersectionObserverWrapper/IntersectionObserverWrapper'),
);

const OurBrand = () => {
  const [showContent, setShowContent] = useState(false);

  return (
    <IntersectionObserverWrapper
      threshold={0.4}
      onElementSeen={() => setShowContent(true)}
    >
      <OurBrandContent showContent={showContent} />
    </IntersectionObserverWrapper>
  );
};

export const OurBrandContent = ({ showContent }: { showContent: boolean }) => (
  <section>
    <div
      className={`brand-image-container image-container ${
        showContent ? 'blur-in' : 'blurred'
      }`}
    />
    <div className="brand-content-container section-padding">
      <div className="brand-row row">
        <div className="our-brand-title">
          <h2>Our brand</h2>
        </div>
        <div className="our-brand-paragraph">
          <p>
            We&rsquo;ve a bold look to match our outlook. Celebrating everything
            we love about travel, it marks a commitment to our mission and
            presents our ambition to the world. But what does it mean for our
            Engineers? Well, it means opportunity. To grow, develop and make an
            impact at every level, from day one.
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default OurBrand;
