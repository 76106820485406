/* eslint-disable react/no-danger */
import { useEffect, useState } from 'react';

import DomPurify from 'dompurify';

import BpkLargeCityCenterIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/city-center';

import { useGetSingleJob } from '../hooks/useGetJobs';

interface JobPageProps {
  jobsSlug: string;
}

const JobPage = ({ jobsSlug }: JobPageProps) => {
  const jobsSlugParts = jobsSlug.split('/');
  const jobId = jobsSlugParts[jobsSlugParts.length - 1];
  const { job, loading } = useGetSingleJob(jobId);
  const [formOpen, setFormOpen] = useState(false);
  const [jobIsValid, setJobIsValid] = useState(false);

  useEffect(() => {
    if (job) {
      // when the job is not found on Greenhouse, 'job' object returns as {status: 404}
      if (job.status && job.status === 404) {
        setJobIsValid(false);
      } else {
        setJobIsValid(true);
      }
    }
  }, [job]);

  useEffect(() => {
    if (formOpen && window !== undefined && document !== undefined) {
      const GrnhseApp = (window as any).Grnhse;
      if (GrnhseApp) {
        GrnhseApp.Settings.scrollOnLoad = false;

        const trackingId = document.cookie.replace(
          /(?:(?:^|.*;\s*)gh_src\s*=\s*([^;]*).*$)|^.*$/,
          '$1',
        );
        if (trackingId) {
          GrnhseApp.Iframe.load(jobId, trackingId);
        } else {
          GrnhseApp.Iframe.load(jobId);
        }
      }
    }
  }, [formOpen]);

  const handleClickApply = () => {
    setFormOpen((prevFormOpen) => !prevFormOpen);
  };

  // This is existing behaviour from the php site. Its not clear why its needed but
  // will leave it in just in case its still needed
  const removeWhiteSpaceBetweenTags = (content: string) =>
    content.replaceAll('&gt; &lt;', '&gt;&lt;');

  /* The purpose of this method is to let Talent include metadata for LinkedIn as
   * regular text in the job description section, but to hide it (by making it white) from anyone
   * viewing said job.
   *
   * Example tags: '#LI-KB1', '#LI-Hybrid'.
   */
  const hideLinkedInTag = (content: string) =>
    content.replaceAll(
      '&gt;#LI-',
      ' id="linkedin-tag" class="jobs__linkedin-tag"&gt;#LI-',
    );

  const decodeHTMLEntities = (result: string) => {
    const e = document.createElement('textarea');
    e.innerHTML = result;
    return e.value;
  };

  const getJobDescriptionHTML = (content: string) => {
    let result = removeWhiteSpaceBetweenTags(content);
    result = hideLinkedInTag(result);
    const html = decodeHTMLEntities(result);
    return DomPurify.sanitize(html);
  };

  return (
    <section className="grid__container">
      {loading && <div />}
      {!loading && !jobIsValid && (
        <div className="job-unavailable-msg">
          This job is no longer available.
        </div>
      )}
      {!loading && job && jobIsValid && (
        <div className="grid__row content-block">
          <div className="grid__column--12 grid__column--mobile-12">
            <p className="page-sub-title">{job.departments[0].name}</p>
            <h1 className="post-title">{job.title}</h1>
            <p className="post-location sub-heading">
              <BpkLargeCityCenterIcon className="city-center-icon" />
              {job.location.name}
            </p>

            <div id="job-description-intro">
              <p>
                Travel. It&apos;s one of life&apos;s simple pleasures. But,
                ooof, planning it can feel like hard work. For the last 20 years
                we&apos;ve been on a mission to empower every traveller with
                innovative tools, insights and experiences that help people get
                to where they&apos;re going, so they can spend more time out
                exploring the world, and less time anxiously bouncing around 25
                browser tabs and endless spreadsheets. Our traveller-first
                approach drives everything we do and it&apos;s never been more
                important than it is today.
              </p>
              <p>
                When we launched as a flight comparison site back in 2003, we
                changed the game by helping travellers feel smart, like they
                were in on a secret, that promise hasn&apos;t changed. And
                today, while we&apos;re a world leader in travel, with millions
                of people using our products every single day we know
                there&apos;s so much more we can be doing.
              </p>
              <p>
                Being part of Skyscanner means joining a team hell bent on
                building the world&apos;s most innovative, and helpful travel
                experiences possible. We&apos;re hugely ambitious and believe we
                have the chance to genuinely challenge and disrupt the industry.
              </p>
            </div>

            <div
              id="job-description"
              dangerouslySetInnerHTML={{
                __html: getJobDescriptionHTML(job.content),
              }}
            />

            <hr aria-hidden="true" />
            <div id="job-description-outro">
              <p>
                You&apos;ll join a brilliantly diverse group from all corners of
                the world. After all, travel is about finding new perspectives
                and experiencing new people and cultures - and Skyscanner is
                strongest when our teams are both inclusive and diverse.
              </p>
              <p>
                Skyscanner is an equal opportunities employer, and all qualified
                applicants will be considered for employment without
                discrimination based on protected characteristics such as age,
                ethnicity, race, religion or belief, political views, sexual
                orientation, sex, gender (whether identity, reassignment, or
                expression), pregnancy or maternity, marital status, disability
                or any other category protected by law.
              </p>
              <p>
                We are committed to ensuring our application and recruitment
                processes are inclusive and accessible to everyone. If you
                require any reasonable adjustments or accommodations, please let
                our recruitment team know.
              </p>
            </div>
            <button
              id="apply"
              className="job_apply"
              type="button"
              onClick={handleClickApply}
            >
              {formOpen ? 'Cancel' : 'Apply Now'}
            </button>
          </div>
        </div>
      )}
      <div
        className="greenhouse-wrapper"
        style={{ display: formOpen ? 'inherit' : 'none' }}
      >
        <div id="grnhse_app" />
      </div>
    </section>
  );
};

export default JobPage;
