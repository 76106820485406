import { useState } from 'react';

import loadable from '@loadable/component';

const IntersectionObserverWrapper = loadable(
  () =>
    import('../../../IntersectionObserverWrapper/IntersectionObserverWrapper'),
);

const Faces = () => {
  const [showContent, setShowContent] = useState(false);

  return (
    <IntersectionObserverWrapper
      threshold={0.1}
      onElementSeen={() => setShowContent(true)}
    >
      <FacesContent showContent={showContent} />
    </IntersectionObserverWrapper>
  );
};

export default Faces;

export const FacesContent = ({ showContent }: { showContent: boolean }) => (
  <section className="faces-container">
    <div className="faces-left">
      <div className="faces-text">
        <p className={showContent ? `fade-in-left` : `hidden`}>
          Andrew Phillips is CTO at Skyscanner. He joined the company as a grad
          back in 2009 and by 2021 was leading an engineering org of over 600.
        </p>
        <p className={showContent ? `fade-in-left-delay-1` : `hidden`}>
          <i>
            &ldquo;I&rsquo;ve always said, if I haven&rsquo;t changed my role
            significantly every two years, if I ever stop learning, that&rsquo;s
            when I&rsquo;ll leave. It&rsquo;s been 13 years and that&rsquo;s yet
            to happen — that&rsquo;s the beauty of Skyscanner. We want every
            engineer to feel like they&rsquo;re constantly learning and have the
            opportunity to change direction or jump into a new engineering area
            if they want to.&rdquo;
          </i>
        </p>
        <p className={showContent ? `fade-in-left-delay-15` : `hidden`}>
          Andrew now really enjoys &rsquo;building the bench&rsquo; &ndash;
          building teams, building people and bringing them along on the
          journey.
        </p>
        <p className={showContent ? `fade-in-left-delay-2` : `hidden`}>
          <a
            className="underline"
            href="https://medium.com/@SkyscannerEng/from-grad-to-vp-my-journey-to-leading-skyscanners-engineering-teams-globally-793f1cd44cf0"
            target="_blank"
            rel="noreferrer"
          >
            From grad to VP: Read about Andrew Phillips&rsquo; journey{' '}
          </a>
          from engineering graduate to leading Skyscanner&rsquo;s engineering
          teams globally.
        </p>
      </div>
    </div>
    <div className="faces-right" />
  </section>
);
