import { useState } from 'react';

import loadable from '@loadable/component';

const IntersectionObserverWrapper = loadable(
  () =>
    import('../../../IntersectionObserverWrapper/IntersectionObserverWrapper'),
);

const TheStorySoFar = () => {
  const [showContent, setShowContent] = useState(false);

  return (
    <IntersectionObserverWrapper
      threshold={0.2}
      onElementSeen={() => setShowContent(true)}
    >
      <TheStorySoFarContent showContent={showContent} />
    </IntersectionObserverWrapper>
  );
};

export default TheStorySoFar;

export const TheStorySoFarContent = ({
  showContent,
}: {
  showContent: boolean;
}) => (
  <section className="story-so-far section-padding">
    <div className="story-content-container row">
      <h2
        className={`story-so-far-title ${showContent ? 'fade-in' : 'hidden'}`}
      >
        The story so far
      </h2>
    </div>
    <div className="story-middle-row row">
      <div className={`left ${showContent ? 'fade-in-left' : 'hidden'}`}>
        <h3 className="story-strapline">
          We&apos;re proud to be the world&apos;s most trusted travel site.
        </h3>
      </div>
      <div className={`right ${showContent ? 'fade-in-right' : 'hidden'}`}>
        <div className="story-paragraph-container">
          <p>
            And we&apos;re not just saying that &ndash; our travellers do. Since
            we burst onto the scene in 2003 to change the way everyone
            travelled, we&apos;ve moved beyond flights to offer car hire and
            hotels too.
          </p>
          <p>
            It&rsquo;s all part of our move towards providing a complete travel
            experience. With over 110 million monthly users, we know that we
            have the power to change travel, for good.*
          </p>
        </div>
      </div>
    </div>
    <div className="bottom-row row">
      <p className={`terms ${showContent ? 'fade-in' : 'hidden'}`}>
        * Based on data from Trustpilot traveller reviews for Skyscanner.net and
        global domains for our competitors
      </p>
    </div>
  </section>
);
