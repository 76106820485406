import BpkLargeCityCenterIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/city-center';
import BpkLargeFoodIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/food';
import BpkLargeHeartIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/heart';

import JobList from '../../components/JobList';
import JobsAppHero from '../../components/JobsAppHero';

const EdinburghPage = () => (
  <>
    <JobsAppHero
      hideHero={false}
      bannerType="edinburgh"
      hideHeroMobile={false}
    />
    <section className="grid__container">
      <div className="grid__row">
        <div className="grid__column--12 grid__column--mobile-12">
          <h1 className="offices-header">Edinburgh</h1>
          <div className="offices-body">
            <p>
              As Skyscanner’s birthplace, Edinburgh will always be special to
              us. On top of its welcoming culture, stellar restaurants and pubs,
              galleries, museums, shops, beaches, great outdoors and incredible
              history, it’s also the fastest growing tech hub in the UK.
            </p>
            <p>
              Our Edinburgh office is the home base for around 420 people. And
              while tech and engineering make up a big part of the team here, we
              also have large in-house teams across product, design, marketing
              and business.
            </p>
          </div>
        </div>
      </div>
      <hr aria-hidden="true" />
    </section>
    <section className="grid__container">
      <div className="grid__row content-block">
        <div className="grid__column--4 grid__column--tablet-12">
          <BpkLargeCityCenterIcon className="city-center-icon--large" />
          <br />
          <h2 id="life-section2-col1-header">Where we are</h2>
          <div id="life-section2-col1-body">
            <p>
              Our original one-room base was traded long-ago for an ultra-modern
              flagship office located in the Quartermile district between the
              Old Town and The Meadows.
            </p>
            <p>
              As well as cinematic views of grand old buildings and lush
              greenery, we’re within easy walking distance to the train station
              as well as popular bars, restaurants and cafes.
            </p>
          </div>
        </div>
        <div className="grid__column--4 grid__column--tablet-12">
          <BpkLargeHeartIcon className="heart-icon--blue" />
          <h2 id="life-section2-col2-header">Our culture</h2>
          <div id="life-section2-col2-body">
            <p>
              From the beginning, we’ve been passionate about operating in a
              people-first manner &mdash; making sure we treat our co-workers
              and travellers with respect and kindness while having a whole lot
              of fun along the way.
            </p>
            <p>
              Beyond our regular in-house events, you’ll often find us at team
              lunches or enjoying local gigs, restaurants and pubs.
            </p>
          </div>
        </div>
        <div className="grid__column--4 grid__column--tablet-12">
          <BpkLargeFoodIcon className="food-icon" />
          <h2 id="life-section2-col3-header">Things to do</h2>
          <div id="life-section2-col3-body">
            <p>
              Edinburgh is a hive of entertainment in all its finest forms. Soak
              up our stunning architecture, local charm, delicious food and
              drink and world-famous museums, galleries, comedy and theatres.
            </p>
            <p>
              Into the great outdoors? Hike the insanely scenic Highlands or
              perhaps take it easy in the city’s magnificent Botanic Gardens.
            </p>
          </div>
        </div>
      </div>
    </section>
    <JobList location="Edinburgh" />
  </>
);

export default EdinburghPage;
