import { useState } from 'react';

import JobList from '../../components/JobList';

const ProductPage = () => {
  const [jobCount, setJobCount] = useState(0);

  return (
    <div>
      <section className="grid__container">
        <div className="grid__row content-block">
          <div className="grid__column--12 grid__column--mobile-12">
            <p className="page-sub-title">Build it with us</p>
            <h1 className="teams-header">Product</h1>
            <a
              href="#job-list-section"
              className={`button${jobCount === 0 ? ' button--disabled' : ''}`}
              id="explore-jobs-button"
            >
              Explore jobs
            </a>
            <div className=" content-block">
              <p id="teams-engineering-body">
                As a product-focused, people-centric business, our consumer
                strategy drives how we think and collaborate. We build our
                products based on traveller insights, to either solve a pain
                point or meet an anticipated need.
                <br />
                <br />
                As the world and travel itself changes and evolves, there are
                endless, industry-wide challenges and opportunities where we can
                make a difference. It’s our mission to make a positive impact by
                identifying and solving these issues together, to improve the
                traveller experience.
                <br />
                <br />
                Working closely with various teams and disciplines across the
                company, including Engineering and Design, Insights and
                Analytics, Data Science, Marketing, and Commercial, our team is
                responsible for designing Skyscanner’s product roadmap and
                bringing it to life for our travellers.
                <br />
                <br />
                With multiple new product launches in the pipeline, we’re
                excited to inspire our travellers with even richer, more
                personalised content, as well as launching new tools to make
                their travel booking experience even easier. We’re also busy
                building powerful tools for our partners to support their
                strategic and operational goals.
                <br />
                <br />
                Skyscanner is The Real Deal. Our people are The Real Deal. It’s
                why we continue to thrive, together. We have a supportive,
                tight-knit community, with team off-sites a regular occurence.
                We also make sure there are plenty of learning opportunities and
                resources available to help our people achieve their goals and
                develop their careers.
              </p>
            </div>
          </div>
        </div>
        <hr aria-hidden="true" />
      </section>
      <section className="grid__container">
        <div className="grid__row center-row content-block">
          <div className="grid__column--5 grid__column--tablet-12 video-container--portrait">
            <video
              className="video"
              controls
              src="https://content.skyscnr.com/m/373ccc12bf6addf2/original/Skyscanner-Priya-Social-9x16-lockup-subs.mp4"
            >
              <track
                src="../../transcripts/Skyscanner-Priya-Social-9x16-lockup-subs-en-US.vtt"
                kind="captions"
                label="English"
              />
            </video>
          </div>
          <div className="grid__column--7 grid__column--tablet-12">
            <div className="skyscanner-quote">
              <p className="quote">
                “Being a small team comes with great responsibility to build
                great products at speed and of quality. Let&rsquo;s say
                it&rsquo;s never a boring day at Skyscanner.”
              </p>
              <p className="sub-heading">
                Priya Chhetri, Principal Product Manager
              </p>
            </div>
          </div>
        </div>
      </section>
      <JobList
        department="Product"
        setJobCountCallback={(count) => setJobCount(count)}
      />
    </div>
  );
};

export default ProductPage;
