import type { ReactElement } from 'react';

import BpkLargeRightChevron from '@skyscanner/backpack-web/bpk-component-icon/lg/chevron-right';

interface Props {
  code: string;
  count?: number | undefined;
  icon?: ReactElement | undefined;
  path?: string | undefined;
  title: string;
  variation: CARD_VARIATION;
  svg?: string;
}

type CARD_VARIATION = 'office' | 'teams' | 'early-careers';

const Card = ({
  code,
  count = undefined,
  icon = undefined,
  path = undefined,
  svg = undefined,
  title,
  variation,
}: Props) => {
  let jobWords = '';
  if (count !== undefined) {
    if (count === 0) {
      jobWords = 'Learn more';
    } else {
      jobWords = `job${count !== 1 ? 's' : ''} available`;
    }
  }
  const href = `/jobs/${path ?? title.toLowerCase()}`;
  return (
    <li className="card-container">
      <a href={href} className={`card card--${variation}`}>
        <div className={`card__body card--${code.toLowerCase()}`}>
          {icon}
          {svg && (
            <img
              aria-hidden="true"
              className="early-careers-card__icon ls-is-cached lazyloaded"
              src={svg}
              alt={title}
            />
          )}
          <div className="card__title">
            <p>{title}</p>
            <h2 aria-hidden="true">{code}</h2>
          </div>
        </div>
        <p className="card__footer">
          {count !== 0 && <strong className="job-count">{count}</strong>}
          <span
            className={`job-words ${
              count === 0 ? 'job-words--learn-more' : ''
            }`}
          >
            {jobWords}
          </span>
          <BpkLargeRightChevron className="right-chevron-icon" />
        </p>
      </a>
    </li>
  );
};

export default Card;
