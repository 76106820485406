import BpkLargeCityCenterIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/city-center';
import BpkLargeFoodIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/food';
import BpkLargeHeartIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/heart';

import JobList from '../../components/JobList';
import JobsAppHero from '../../components/JobsAppHero';

const MiamiPage = () => (
  <>
    <JobsAppHero hideHero={false} bannerType="miami" hideHeroMobile={false} />

    <section className="grid__container">
      <div className="grid__row">
        <div className="grid__column--12 grid__column--mobile-12">
          <h1 className="offices-header">Miami</h1>
          <div className="offices-body">
            <p>
              White-sand beaches, tropical climate, delicious cuisine,
              incredible culture, stunning architecture, amazing nature and
              Latin-American influences. Miami is energy encapsulated.
            </p>
            <p>
              Our first team in America features a close-knit group of 24
              talented, multi-skilled individuals who come together from all
              corners of Florida’s most stylish city. English and Spanish
              speaking, we look after the North American and LATAM markets.
            </p>
          </div>
        </div>
      </div>
      <hr aria-hidden="true" />
    </section>

    <section className="grid__container">
      <div className="grid__row content-block">
        <div className="grid__column--4 grid__column--tablet-12">
          <BpkLargeCityCenterIcon className="city-center-icon--large" />
          <br />
          <h2 id="life-section2-col1-header">Where we are</h2>
          <div id="life-section2-col1-body">
            <p>
              We’re a small team here in Miami and have the luxury of working
              from various co-working hubs across the city including Brickell,
              South Beach, Downtown, Wynwood, and Coral Gables.
            </p>
            <p>
              The offices are all beautifully designed with dynamic open spaces
              and large kitchens. We regularly use the Brickell location which
              is conveniently based in the popular Brickell City Center Mall.
            </p>
          </div>
        </div>
        <div className="grid__column--4 grid__column--tablet-12">
          <BpkLargeHeartIcon className="heart-icon--blue" />
          <h2 id="life-section2-col2-header">Our culture</h2>
          <div id="life-section2-col2-body">
            <p>
              Collaborative, dynamic and top performing, we’re passionate about
              what we do and how we treat and support one another.
            </p>
            <p>
              We’ll often gather after work at rooftop venues or waterside
              restaurants and bars with magnificent sunset views. We also make a
              point of celebrating our achievements with our awesome Summer BBQ
              and fun-filled end-of-year party.
            </p>
          </div>
        </div>
        <div className="grid__column--4 grid__column--tablet-12">
          <BpkLargeFoodIcon className="food-icon" />
          <h2 id="life-section2-col3-header">Things to do</h2>
          <div id="life-section2-col3-body">
            <p>
              Also known as ‘The Magic City’, Miami boasts a fantastic variety
              of experiences and activities beyond the beaches and art deco
              design.
            </p>
            <p>
              Shop for specialty ingredients in Little Havana or Bayside Market,
              people watch and soak in the rays at Miami Beach, take a dip in
              Coral Gables’ 1920s Venetian Baths or get back to nature and
              explore mangrove forests, salt marshes and lush, tropical
              landscapes.
            </p>
          </div>
        </div>
      </div>
    </section>
    <JobList location="Miami" />
  </>
);

export default MiamiPage;
