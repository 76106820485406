import { useEffect, useState } from 'react';

import {
  GREENHOUSE_JOBS_URL,
  GREENHOUSE_SINGLE_JOB_BASE_URL,
} from '../utils/constants';

type GreenhouseJobDepartment = {
  id: number;
  name: string;
  parent_id: number;
  child_ids: number[];
};

type GreenhouseJob = {
  id: string;
  absolute_url: string;
  requisition_id: number;
  location: { name: string };
  departments: GreenhouseJobDepartment[];
  offices: [
    {
      id: number;
      name: string;
      location: string;
      parent_id: number;
      child_ids: number[];
    },
  ];
  title: string;
  metadata: [{ name: string; value: string }];
  content: string;
  status: number;
};

export const useGetJobs = () => {
  const [jobs, setJobs] = useState<GreenhouseJob[]>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const res = await fetch(GREENHOUSE_JOBS_URL);
        const resData = (await res.json()) as { jobs: GreenhouseJob[] };
        if (resData) {
          const validJobs = resData.jobs
            .filter((job: GreenhouseJob) => {
              const hide = job.metadata.find(
                (meta) => meta.name === 'Hide from job site',
              );
              if (!hide) {
                return true;
              }
              return !hide.value;
            })
            .sort(
              (a: GreenhouseJob, b: GreenhouseJob) =>
                b.requisition_id - a.requisition_id,
            );
          setJobs(validJobs);
        }
      } catch (err) {
        // This is the existing behaviour, not ideal handling but is unchanged
        setJobs([]);
      } finally {
        setLoading(false);
      }
    };

    fetchJobs();
  }, []);

  return { jobs, loading };
};

export const useGetSingleJob = (jobId: string) => {
  const [job, setJob] = useState<GreenhouseJob>();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const res = await fetch(`${GREENHOUSE_SINGLE_JOB_BASE_URL}/${jobId}`);
        const listing = (await res.json()) as GreenhouseJob;
        if (listing) {
          setJob(listing);
        }
      } catch (err) {
        // This is the existing behaviour, not ideal handling but is unchanged
        setJob(undefined);
      } finally {
        setLoading(false);
      }
    };

    fetchJobs();
  }, [jobId]);

  return { job, loading };
};
