import Card from '../components/Card';
import JobList from '../components/JobList';
import JobsAppHero from '../components/JobsAppHero';

const EarlyCareersPage = () => (
  <div>
    <JobsAppHero bannerType="early-careers" />
    <section className="grid__container">
      <div className="grid__row">
        <div className="grid__column--padded grid__column--tablet-12">
          <div className="skyscanner-quote">
            <h1>Early careers</h1>
            <p>
              <strong>Your journey starts here</strong>
            </p>
            <p />
            <p>
              Travel is about new experiences and making memories that last.
              Working at Skyscanner is the same, with amazing opportunities for
              people starting their professional journey.
            </p>
            <p>
              We have lots of experience nurturing fresh talent at Skyscanner.
              In fact, some of our senior leaders started in Early Careers when
              they first joined. Whether you’re joining on work experience, or
              as an intern, graduate or new starter, everyone at Skyscanner is
              part of a team changing how the world travels.
            </p>
            <p>
              Our graduate programme is designed to give you depth and breadth
              of experience and is divided into three rotations within your
              chosen business area, where you’ll tackle real-life projects using
              the latest technology.
            </p>
            <p>
              Skyscanner is The Real Deal. Our people are The Real Deal. It’s
              why we continue to thrive, together. We’ve built Skyscanner’s
              culture as a place where you can bring your full self to work, so
              you can be free to be you while making an impact every day.
            </p>
          </div>
        </div>
      </div>
      <hr aria-hidden="true" />
    </section>
    <section className="grid__container">
      <div className="grid__row content-block">
        <div className="grid__column--12">
          <h2>Join and grow with us</h2>
          <ul className="cards-list cards-list--early-careers columns">
            <Card
              code="GRD"
              title="Graduate"
              variation="early-careers"
              svg="https://images.skyscnr.com/sttc/jobssite/svg/graduate-caps-throwing.e3a64abf55e32eb6cf62.svg"
              count={0}
              path="earlycareers/graduates"
            />
            <Card
              code="INT"
              title="Internship"
              variation="early-careers"
              svg="https://images.skyscnr.com/sttc/jobssite/svg/luggage-4.f887183fdf0ac38e6d76.svg"
              count={0}
              path="earlycareers/intern"
            />
          </ul>
        </div>
      </div>
      <hr aria-hidden="true" />
    </section>
    <section className="grid__container">
      <div className="grid__row center-row content-block">
        <div className="grid__column--5 grid__column--tablet-12 photo-container">
          <img
            className="photo"
            alt="Three people on a coffee break"
            src="https://content.skyscnr.com/m/61169b8f4ae1e061/Medium-EarlyCareers_Image_421x331.png"
          />
        </div>
        <div className="grid__column--7 grid__column--tablet-12">
          <div className="skyscanner-quote">
            <p className="quote">
              “Skyscanner’s graduate programme opened doors to diverse teams and
              areas, which has helped me to grow as I rotated across different
              amazing teams and locations. It was definitely an invaluable
              experience that fuelled my personal and professional development.”
            </p>
            <p className="sub-heading">
              Toni Realp, Graduate Software Engineer, 2022
            </p>
          </div>
        </div>
      </div>
      <hr aria-hidden="true" />
    </section>
    <section className="grid__container">
      <div className="grid__row content-block">
        <div className="how-to-apply grid__column--12">
          <div className="how-to-apply__body">
            <h2>How to apply</h2>
            <p>
              Curious how the whole process works? We can break it down for you
              in four easy steps.
            </p>
            <a href="/jobs/earlycareers/how-to-apply" className="button">
              Find out more
            </a>
          </div>
          <img
            alt="graduate caps being thrown"
            className="ls-is-cached lazyloaded"
            src="https://images.skyscnr.com/sttc/jobssite/svg/graduate-caps-throwing.e3a64abf55e32eb6cf62.svg"
          />
        </div>
      </div>
    </section>
    <JobList department="Early Careers" />
  </div>
);

export default EarlyCareersPage;
