const HowToApplyPage = () => (
  <div>
    <div className="grid__container">
      <div className="grid__row content-block" style={{ paddingBottom: '0' }}>
        <div className="how-to-apply-main-header-container grid__column--6 grid__column--mobile-12">
          <p className="page-sub-title">Early careers</p>
          <h1 className="how-to-apply-main-header teams-header">
            How to apply
          </h1>
          <p>
            Found the programme that’s right for you? Here’s what comes next:
          </p>
        </div>
        <div className="grid__column--6">
          <img
            alt="graduate caps being thrown"
            className="early-careers-header-svg ls-is-cached lazyloaded"
            src="https://images.skyscnr.com/sttc/jobssite/svg/graduate-caps-throwing.e3a64abf55e32eb6cf62.svg"
          />
        </div>
      </div>
      <div>
        <hr aria-hidden="true" />
      </div>
      <div className="grid__row content-block">
        <div className="grid__column--3 grid__column--tablet-12">
          <div className="how-to-apply-icon">
            <span className="how-to-apply-icon__number">1</span>
          </div>
          <h2 className="how-to-apply-overview how-to-apply-sub-header">
            Apply
          </h2>
          <p id="life-section2-col1-body">
            Fill in our online application.
            <br />
            But first <a href="#more-tips">read our tips</a>.
          </p>
        </div>
        <div className="grid__column--3 grid__column--tablet-12">
          <div className="how-to-apply-icon">
            <span className="how-to-apply-icon__number">2</span>
          </div>
          <h2 className="how-to-apply-overview how-to-apply-sub-header">
            Interview
          </h2>
          <p id="life-section2-col2-body">
            If we like your application, we’ll do a video interview.
          </p>
        </div>
        <div className="grid__column--3 grid__column--tablet-12">
          <div className="how-to-apply-icon">
            <span className="how-to-apply-icon__number">3</span>
          </div>
          <h2 className="how-to-apply-overview how-to-apply-sub-header">
            Discovery Day
          </h2>
          <p id="life-section2-col3-body">
            This will be an all day session, split into a number of different
            activities and interviews (with breaks!).
          </p>
        </div>
        <div className="grid__column--3 grid__column--tablet-12">
          <div className="how-to-apply-icon">
            <span className="how-to-apply-icon__number">4</span>
          </div>
          <h2 className="how-to-apply-overview how-to-apply-sub-header">
            Accept
          </h2>
          <p id="life-section2-col4-body">
            Once we’ve met the candidates, we’ll make our decisions.
          </p>
        </div>
      </div>
      <div>
        <hr aria-hidden="true" />
      </div>
      <div className="grid__row content-block">
        <div className="grid__column--12">
          <h2 className="how-to-apply-header">How it works</h2>
          <p>
            We welcome applications from all universities and are proud to be an
            inclusive employer.
          </p>
          <p>
            For all global positions, applications are received online via CV
            and covering letter, then reviewed and responded to by a member of
            the Early Careers team. If you are a good match you’ll be invited to
            a video interview with one of the Early Careers Team and, for
            engineers, a coding test.
          </p>
          <p>
            This is followed by a Discovery Day, featuring a group exercise, and
            culture and technical interviews. The Discovery Day is your chance
            to meet us, ask any questions and help us explore your interests and
            experiences.
          </p>
          <div>
            <hr aria-hidden="true" />
          </div>
          <p>
            <strong>Specifically for the United Kingdom and Barcelona:</strong>
          </p>
          <p />
          <p>
            If your video interview and coding test (for Software engineers) is
            successful, a Discovery day will follow. A Discovery Day, will
            feature a group exercise, as well as culture and technical
            interviews. The Discovery Day is your chance to meet us, ask any
            questions and help us explore your interests and experiences.
          </p>
          <p>
            <strong>Specifically for Shenzhen:</strong>
          </p>
          <p />
          <p>
            Following a successful video interview (and coding test for Software
            Engineers) you will be invited to a virtual technical and cultural
            interview.
          </p>
        </div>
      </div>
    </div>
    <div className="grid__background--gray text-container">
      <div className="grid__container">
        <div className="grid__row content-block" style={{ paddingBottom: '0' }}>
          <div className="grid__column--12">
            <h2 className="how-to-apply-header">
              <div className="how-to-apply-icon how-to-apply-icon--lower-section">
                <span className="how-to-apply-icon__number">1</span>
              </div>
              The application
            </h2>
          </div>
        </div>
        <p className="space-after-text">
          Applications are read by real humans! A member of the Early Careers
          squad will review your application, looking at your answers and CV.
        </p>
        <p className="space-after-text">
          If we like what we see, we’ll be in touch to arrange an interview. If
          you’re applying for an Engineering role you’ll be asked to complete a
          coding test prior to being invited to interview.
        </p>
        <p className="how-to-apply-tips">
          <strong>Tips</strong>
        </p>
        <ul className="how-to-apply-unordered-list how-to-apply-list space-after-text">
          <li>
            Remember to answer all the application questions fully - this is
            your chance to stand out. We ask the questions because we want to
            discover more about you than perhaps what your CV might tell us.
          </li>
        </ul>
        <p className="how-to-apply-your-cv">
          Your CV (make sure you highlight your achievements):
        </p>
        <ul className="how-to-apply-second-unordered-list how-to-apply-list">
          <li>Keep it short and clear</li>
          <li>Match your skills and experience with the job description</li>
          <li>
            If you are a recent graduate or have limited work experience,
            include school-related projects or coursework that demonstrate
            relevant skills and knowledge
          </li>
        </ul>
      </div>
    </div>
    <div className="grid__container">
      <div className="how-to-apply-the-interview-container">
        <div className="grid__row content-block" style={{ paddingBottom: '0' }}>
          <div className="grid__column--12">
            <h2 className="how-to-apply-header">
              <div className="how-to-apply-icon how-to-apply-icon--lower-section">
                <span className="how-to-apply-icon__number">2</span>
              </div>
              The interview
            </h2>
          </div>
        </div>
        <p className="space-after-text">
          If you are successful at the first stage, we’ll invite you to a 30
          minute video interview. It’s all done over Zoom, the online video
          conferencing software we use.
        </p>
        <p className="how-to-apply-tips">
          <strong>Tips</strong>
        </p>
        <ul className="how-to-apply-unordered-list how-to-apply-list space-after-text">
          <li>Find a quiet spot with good WiFi or 4G connection</li>
          <li>
            Be prepared for behavioural, hypothetical or case-based questions
            that cover your role-related knowledge
          </li>
          <li>
            Questions will be open-ended. Make sure you answer the question
            being asked and if you need clarification, just ask
          </li>
        </ul>
        <p className="how-to-apply-tips">
          <strong>Discovery Day</strong>
        </p>
        <p className="how-to-apply-discovery-day-item space-after-text">
          The Discovery Day is your chance to meet us, ask any questions and
          help us explore your interests and experiences.
        </p>
        <p className="how-to-apply-discovery-day-second-item">
          It will feature a group exercise, a culture and technical interview as
          well as a networking lunch and a chance to meet some of our current
          graduates.
        </p>
        <a href="#more-tips">See more interview tips below</a>
      </div>
      <div>
        <hr aria-hidden="true" />
      </div>
    </div>
    <div className="grid__container--full-width">
      <div className="grid__container content-block">
        <div className="grid__row center-row">
          <div className="grid__column--5 grid__column--tablet-12 photo-container">
            <img
              className="photo"
              alt="Jonas Djondo, Graduate Software Engineer, 2022"
              src="https://images.skyscnr.com/sttc/jobssite/img/jonas.73c90840fd79d6d6b9e9.jpg"
            />
          </div>
          <div className="grid__column--6 grid__column--tablet-12">
            <div className="skyscanner-quote">
              <p className="quote">
                “Always be curious! Prepare a few questions for the interviewer
                as it can give you an upper hand - it can help display your
                genuine interest in the role you are applying for and Skyscanner
                in general.”
              </p>
              <p className="sub-heading">
                Jonas Djondo, Graduate Software Engineer, 2022
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="grid__background--gray text-container">
      <div className="grid__container" id="discovery-day">
        <div>
          <div
            className="grid__row content-block"
            style={{ paddingBottom: '0' }}
          >
            <div className="grid__column--12">
              <h2 className="how-to-apply-header">
                <div className="how-to-apply-icon how-to-apply-icon--lower-section">
                  <span className="how-to-apply-icon__number">3</span>
                </div>
                The discovery day
              </h2>
            </div>
          </div>
          <p>
            ** Note that this stage applies for Scotland and Barcelona only. For
            Shenzhen candidates this stage will be done remotely and will
            consist of only the Culture and Technical interviews.
          </p>
          <p>
            The Discovery Day will be an all day session, split into a number of
            different activities and interviews (with breaks!). It is your
            chance to meet us, ask any questions and help us explore your
            interests and experiences. It will feature a group exercise, a
            culture and technical interview as well as a networking lunch and a
            chance to meet some of our current graduates.
          </p>
          <p>
            The morning session consists of a 90 minute group exercise followed
            by a short presentation and Q&amp;A.
          </p>
          <p className="how-to-apply-discovery-day-second-item">
            In the afternoon, you’ll have two individual interviews. One will be
            a cultural interview and the other will be more focused on the role
            you have applied for.
          </p>
          <p>
            <strong>What does the group interview involve?</strong>
            <br />
            You will be split into groups of 4. You will be given a typical
            scenario that our staff may have to deal with here at Skyscanner.
            You will then work through the exercise with your group and present
            your ideas and solutions to your peers in a 5-10 minute presentation
            including a Q&amp;A.
          </p>
          <p>
            <strong>What does the business specific interview involve?</strong>
            <br />
            You will be given a typical problem that our staff at Skyscanner
            might face. You’ll then work through the problem with your
            interviewer, using the whiteboard, talking them through your thought
            process to get to a solution.
          </p>
          <p>
            <strong>What is a culture interview?</strong>
            <br />
            This is a chance for us to get to know more about who you are. It’s
            a bit like your initial Zoom interview but this time we’ll dig a
            little deeper into your answers. It’s also a chance for you to learn
            a bit more about the culture here at Skyscanner.
          </p>
          <p>
            <strong>Is there anything I need to prepare?</strong>
            <br />
            There is no advance preparation required. Your interviewer will talk
            you though the group exercise and interview exercise. If there’s
            anything you are unsure about, just ask!
          </p>
          <p>
            <strong>What should I wear?</strong>
            <br />
            Whatever you like! We’ve a very relaxed culture at Skyscanner and
            our dress code is casual. Please come to your interview in something
            you feel comfortable wearing.
          </p>
        </div>
      </div>
    </div>
    <div className="grid__container text-container">
      <div className="grid__row content-block" style={{ paddingBottom: '0' }}>
        <div className="grid__column--12">
          <h2 className="how-to-apply-header">
            <div className="how-to-apply-icon how-to-apply-icon--lower-section">
              <span className="how-to-apply-icon__number">4</span>
            </div>
            The decision
          </h2>
        </div>
      </div>
      <p className="space-after-text">
        After the interviews are done our team will meet to review and discuss
        each candidate at each interview stage. This is overseen by the Early
        Careers squad to ensure there is consistency across the process.
      </p>
      <p className="space-after-text">
        Once a decision has been made, a member of the Early Careers squad will
        be in touch. We will provide feedback to all candidates at this stage.
      </p>
      <p className="space-after-text">
        If you’re successful, a member of the Early Careers squad will contact
        you directly to let you know. Next we’ll get your contract ready and
        send it out asap.
      </p>
      <p className="space-after-text">
        We’ll continue to keep in touch until your first day in the office and
        if you’ve any questions, let us know.
      </p>
    </div>
    <div className="grid__background--gray text-container">
      <div className="grid__container">
        <div
          id="more-tips"
          className="grid__row content-block"
          style={{ paddingBottom: '0' }}
        >
          <div className="grid__column--12 grid__column--tablet-12">
            <h2 className="how-to-apply-header">
              Our top tips for interviewing
            </h2>
            <ul className="how-to-apply-list">
              <li>
                <strong>Predict:</strong> You can guess a lot of the questions
                you’ll be asked, like “Why do you want this job?” and “What’s a
                tough problem you’ve solved?” If you can’t think of any, Google
                “most common interview questions.”
              </li>
              <li>
                <strong>Explain:</strong> We want to understand how you think,
                so explain your thought process and decision making throughout
                the interview. Remember we’re not only evaluating your technical
                ability, but also how you approach problems and try to solve
                them. Explicitly state and check assumptions with your
                interviewer to ensure they are reasonable.
              </li>
              <li>
                <strong>Clarify:</strong> Many of the questions will be
                deliberately open-ended to provide insight into what categories
                and information you value within the technological puzzle. We’re
                looking to see how you engage with the problem and your primary
                method for solving it. Be sure to talk through your thought
                process and feel free to ask specific questions if you need
                clarification.
              </li>
              <li>
                <strong>Improve:</strong> Think about ways to improve the
                solution you present. It’s worthwhile to think out loud about
                your initial thoughts to a question. In many cases, your first
                answer may need refining and further explanation. If necessary,
                start with the brute force solution and improve on it - just let
                the interviewer know that’s what you’re doing and why.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <hr aria-hidden="true" />
        </div>
      </div>

      <div className="grid__container--full-width">
        <div className="grid__container">
          <div className="grid__row center-row">
            <div className="grid__column--5 grid__column--tablet-12 photo-container">
              <img
                className="photo"
                alt="Chelsea Tessendorf, Early Careers Senior Executive"
                src="https://images.skyscnr.com/sttc/jobssite/img/chelsea.e1dd3af3cbd79933965e.jpg"
              />
            </div>
            <div className="grid__column--6 grid__column--tablet-12">
              <div className="skyscanner-quote">
                <p className="quote">
                  “At our Discovery Day, there are no right or wrong answers,
                  and no bad questions. We want to hear your ideas and process
                  but we want to give you space to ask about the work, the team
                  and the culture as well!”
                </p>
                <p className="sub-heading">
                  Chelsea Tessendorf, Early Careers Senior Executive
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default HowToApplyPage;
