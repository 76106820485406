import BpkLargeCityCenterIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/city-center';
import BpkLargeFoodIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/food';
import BpkLargeHeartIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/heart';

import JobList from '../../components/JobList';
import JobsAppHero from '../../components/JobsAppHero';

const LondonPage = () => (
  <>
    <JobsAppHero hideHero={false} bannerType="london" hideHeroMobile={false} />

    <section className="grid__container">
      <div className="grid__row">
        <div className="grid__column--12 grid__column--mobile-12">
          <h1 className="offices-header">London</h1>
          <div className="offices-body">
            <p>
              As one of the most multicultural, exciting, travel and
              tech-obsessed cities on the planet, setting up shop in London was
              an absolute no-brainer. And with an endless number of things to
              see, do and experience on our doorstep, it’s a place full of
              discovery, surprise and adventure (even if it’s just for an hour
              at lunch).
            </p>
            <p>
              We’ve built a dynamic, diverse team of over 360 across multiple
              disciplines and we’re always looking for top-notch talent. Check
              out our live roles to learn more.
            </p>
          </div>
        </div>
      </div>
      <hr aria-hidden="true" />
    </section>

    <section className="grid__container">
      <div className="grid__row content-block">
        <div className="grid__column--4 grid__column--tablet-12">
          <BpkLargeCityCenterIcon className="city-center-icon--large" />
          <br />
          <h2 id="life-section2-col1-header">Where we are</h2>
          <div id="life-section2-col1-body">
            <p>
              Our new office opened in 2023 right in the heart of London’s
              famous Soho.
            </p>
            <p>
              Touching Oxford Street and Covent Garden, we’re moments away from
              the world’s largest brands and retailers, with some of London’s
              greatest attractions, pubs, clubs, bars and restaurants a short
              walk away.
            </p>
          </div>
        </div>
        <div className="grid__column--4 grid__column--tablet-12">
          <BpkLargeHeartIcon className="heart-icon--blue" />
          <h2 id="life-section2-col2-header">Our culture</h2>
          <div id="life-section2-col2-body">
            <p>
              Our London team is built around community and inclusion. Our
              large, shared spaces and open-plan floor encourages collaboration,
              good vibes and meeting new people.
            </p>
            <p>
              A typical week includes lots of delicious free food and drink,
              guest lunchtime speakers and the occasional ping-pong battle.
              While many of us head to local parks, restaurants and pubs after
              work.
            </p>
          </div>
        </div>
        <div className="grid__column--4 grid__column--tablet-12">
          <BpkLargeFoodIcon className="food-icon" />
          <h2 id="life-section2-col3-header">Things to do</h2>
          <div id="life-section2-col3-body">
            <p>
              From thrifting for bargains at Portobello Market to deer-spotting
              in Richmond Park, exploring history at the Tower of London to
              soaking up the sights at Kew Gardens, Southbank, Shoreditch and
              hilly Hampstead, it’s a city with something for everyone.
            </p>
          </div>
        </div>
      </div>
    </section>
    <JobList location="London" />
  </>
);

export default LondonPage;
