import { useState, useEffect } from 'react';

interface SideNavProps {
  showSideMenu: boolean;
  toggleSideMenu: () => void;
}

const SideNav = ({ showSideMenu, toggleSideMenu }: SideNavProps) => {
  const [shouldTransition, setShouldTransition] = useState(false);

  /* Side Nav component should not transition on initial page load
    so we should delay adding the transition to the element until after the initial transition timeout
  */
  useEffect(() => {
    setTimeout(() => {
      setShouldTransition(true);
    }, 500);
  }, []);

  return (
    <>
      <nav
        className={`side-nav${shouldTransition ? ' should-transition' : ''}${
          showSideMenu ? ' expanded' : ''
        } `}
        aria-label="Side navigation menu"
      >
        <button
          type="button"
          className="menu-close"
          aria-label="Hide page navigation menu"
          onClick={toggleSideMenu}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            role="img"
          >
            <title>Hide page navigation menu</title>
            <path
              role="presentation"
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            />
          </svg>
        </button>
        <ul className="side-nav-links">
          <li>
            <a href="/jobs/">Welcome</a>
          </li>
          <li>
            <a href="/jobs/current-jobs/">Current jobs</a>
          </li>
          <li>
            <a href="/jobs/life/">Life at Skyscanner</a>
          </li>
          <li>
            <a href="/jobs/teams/">Teams</a>
          </li>
          <li>
            <a href="/jobs/offices/">Locations</a>
          </li>
          <li>
            <a href="/jobs/earlycareers/">Early careers</a>
          </li>
        </ul>
      </nav>

      <button
        type="button"
        className="backdrop"
        onClick={toggleSideMenu}
        tabIndex={-1}
        aria-hidden="true"
        hidden
      />
    </>
  );
};

export default SideNav;
