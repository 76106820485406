import Card from '../../components/Card';
import JobsAppHero from '../../components/JobsAppHero';
import { useGetJobs } from '../../hooks/useGetJobs';

const OfficesPage = () => {
  const offices = [
    { title: 'Barcelona', code: 'BCN' },
    { title: 'Edinburgh', code: 'EDI' },
    { title: 'Glasgow', code: 'GLA' },
    { title: 'London', code: 'LDN' },
    { title: 'Miami', code: 'MIA' },
    { title: 'Shenzhen', code: 'SZX' },
    { title: 'Singapore', code: 'SIN' },
    { title: 'Tokyo', code: 'TYO' },
  ];

  const { jobs, loading } = useGetJobs();

  const getJobCountForOffice = (officeTitle: string) => {
    const officeJobs = jobs.filter((job: { location: { name: string } }) =>
      job.location?.name.includes(officeTitle),
    );
    return officeJobs.length;
  };

  return (
    <>
      <JobsAppHero bannerType="offices" />
      <div className="grid__container">
        <div className="grid__row">
          <div className="grid__row">
            <div className="grid__column--12">
              <section className="grid__container">
                <h1 className="offices-header">Our locations</h1>
                <div className="our-locations-text">
                  <p>
                    We’ve come a long way from our humble beginnings in a tiny
                    Edinburgh office in 2003. Now with hubs in some of the most
                    exciting cities in the world and around 1,500 employees,
                    we’re thrilled to be comprised of some of the best talent
                    and teammates on the planet.
                  </p>
                  <p>
                    Discover more about each of our uniquely brilliant
                    Skyscanner locations.
                  </p>
                </div>
                <div>
                  <hr aria-hidden="true" />
                </div>
              </section>
              <section className="grid__container">
                <ul className="cards-list cards-list--left columns">
                  {offices.map((office) => (
                    <Card
                      key={office.code}
                      title={office.title}
                      code={office.code}
                      variation="office"
                      count={
                        loading ? undefined : getJobCountForOffice(office.title)
                      }
                    />
                  ))}
                </ul>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OfficesPage;
