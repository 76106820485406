const PrepPackHero = () => (
  <section className="prep-pack-hero">
    <div className="hero-image-container image-container">
      <div className="logo-container">
        <svg
          className="logo-image"
          width="223"
          height="36"
          viewBox="0 0 223 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          role="img"
        >
          <title>Skyscanner logo</title>
          <g clipPath="url(#clip0_8958_12424)">
            <path
              d="M24.5 30.9091C25.0143 30.9091 25.5042 30.7866 25.9695 30.5172L31.3333 27.4312C32.411 26.8189 33.6601 26.5495 34.9092 26.6965C41.4241 27.4557 45.9796 28.6803 47.3267 29.0722C47.5961 29.1457 47.9145 29.0477 48.0859 28.8273C48.3064 28.5579 48.5758 28.117 48.7962 27.4802C48.9922 26.8679 49.0167 26.3536 48.9922 25.9862C48.9677 25.6923 48.7717 25.4229 48.4778 25.3494C46.3715 24.7371 37.04 22.2144 24.5 22.2144C11.96 22.2144 2.6285 24.7371 0.522172 25.3494C0.228266 25.4229 0.0323296 25.6923 0.00783741 25.9862C-0.0166547 26.3291 0.00783816 26.8434 0.203775 27.4802C0.399713 28.117 0.693619 28.5579 0.914048 28.8273C1.08549 29.0477 1.40389 29.1457 1.67331 29.0722C3.02037 28.6803 7.60041 27.4557 14.0908 26.6965C15.3399 26.5495 16.589 26.8189 17.6667 27.4312L23.0305 30.5172C23.4958 30.7866 24.0102 30.9091 24.5 30.9091Z"
              fill="white"
            />
            <path
              d="M15.5604 13.5442C15.8543 14.0585 16.3196 14.4014 16.8584 14.5483C17.3973 14.6953 17.9606 14.6218 18.4749 14.3279C18.9893 14.034 19.3322 13.5687 19.4791 13.0298C19.6261 12.491 19.5526 11.9277 19.2587 11.4133L14.4337 3.06152C14.2868 2.79211 13.9684 2.69414 13.6745 2.71863C13.2826 2.74312 12.7438 2.93906 12.107 3.30644C11.4702 3.67382 11.0293 4.04121 10.8089 4.3596C10.6374 4.60452 10.5884 4.92292 10.7354 5.19234L15.5604 13.5442Z"
              fill="white"
            />
            <path
              d="M11.1518 19.5203C11.6661 19.8142 12.2539 19.8631 12.7683 19.7407C13.3071 19.5937 13.7724 19.2508 14.0663 18.7365C14.3602 18.2222 14.4092 17.6344 14.2868 17.12C14.1643 16.6057 13.7969 16.1158 13.2826 15.8219L4.93076 10.997C4.66135 10.85 4.34295 10.899 4.09803 11.0705C3.77963 11.2909 3.41225 11.7317 3.04487 12.3685C2.67748 13.0053 2.48155 13.5442 2.45705 13.936C2.43256 14.2544 2.55502 14.5483 2.79994 14.6953L11.1518 19.5203Z"
              fill="white"
            />
            <path
              d="M26.6308 10.5806C26.6308 11.1684 26.3859 11.7073 26.0185 12.0991C25.6266 12.491 25.1123 12.7114 24.5 12.7114C23.9122 12.7114 23.3734 12.4665 22.9815 12.0991C22.5896 11.7073 22.3692 11.1929 22.3692 10.5806V0.955193C22.3692 0.636795 22.5651 0.391874 22.859 0.269413C23.2019 0.0979683 23.7652 0 24.5 0C25.2348 0 25.7981 0.0979683 26.141 0.269413C26.4104 0.416366 26.6308 0.636795 26.6308 0.955193V10.5806Z"
              fill="white"
            />
            <path
              d="M33.4396 13.5442C33.1457 14.0585 32.6804 14.4014 32.1416 14.5483C31.6027 14.6953 31.0394 14.6218 30.5251 14.3279C30.0107 14.034 29.6678 13.5687 29.5209 13.0298C29.3739 12.491 29.4474 11.9277 29.7413 11.4133L34.5663 3.06152C34.7132 2.79211 35.0316 2.69414 35.3255 2.71863C35.7174 2.74312 36.2562 2.93906 36.893 3.30644C37.5298 3.67382 37.9707 4.04121 38.1911 4.3596C38.3626 4.60452 38.4115 4.92292 38.2646 5.19234L33.4396 13.5442Z"
              fill="white"
            />
            <path
              d="M37.8482 19.5203C37.3339 19.8142 36.7461 19.8631 36.2317 19.7407C35.6929 19.5937 35.2276 19.2508 34.9337 18.7365C34.6398 18.2222 34.5908 17.6344 34.7132 17.12C34.8602 16.5812 35.2031 16.1158 35.7174 15.8219L44.0692 10.997C44.3386 10.85 44.657 10.899 44.902 11.0705C45.2204 11.2909 45.5877 11.7317 45.9551 12.3685C46.3225 13.0053 46.5185 13.5442 46.5429 13.936C46.5674 14.2544 46.445 14.5483 46.2001 14.6953L37.8482 19.5203Z"
              fill="white"
            />
            <path
              d="M90.7445 12.7131H94.7776C94.9251 12.7131 95.0481 12.8115 95.1219 12.9344L99.1304 23.2131L103.016 12.9344C103.065 12.7869 103.213 12.7131 103.36 12.7131H107.319C107.59 12.7131 107.762 12.9836 107.639 13.2049L97.8024 35.7787C97.7532 35.9016 97.6057 36 97.4827 36H93.9906C93.7447 36 93.548 35.7295 93.6709 35.5082L97.04 27.6148L90.4494 13.2049C90.3018 12.9836 90.474 12.7131 90.7445 12.7131Z"
              fill="white"
            />
            <path
              d="M150.282 15V13.082C150.282 12.8852 150.454 12.7131 150.65 12.7131H154.216C154.413 12.7131 154.585 12.8852 154.585 13.082V28.4016C154.585 28.5984 154.413 28.7705 154.216 28.7705H150.65C150.454 28.7705 150.282 28.5984 150.282 28.4016V26.3852C149.519 27.5656 147.847 29.1148 145.117 29.1148C139.904 29.1148 137.199 25.1311 137.199 20.6803C137.199 14.877 141.232 12.3934 144.994 12.3934C147.478 12.3689 149.2 13.5492 150.282 15ZM141.502 20.7049C141.502 23.459 143.199 25.5738 145.929 25.5738C148.659 25.5738 150.405 23.7295 150.405 20.9016C150.405 18.0738 148.708 15.9098 145.806 15.9098C143.076 15.9098 141.502 18.0246 141.502 20.7049Z"
              fill="white"
            />
            <path
              d="M157.807 28.4016V13.082C157.807 12.8852 157.979 12.7131 158.176 12.7131H161.741C161.938 12.7131 162.11 12.8852 162.11 13.082V15.1721C162.946 13.7213 164.52 12.3934 167.201 12.3934C169.955 12.3934 173.029 13.7459 173.029 19.9672V28.4016C173.029 28.5984 172.857 28.7705 172.66 28.7705H169.094C168.898 28.7705 168.726 28.5984 168.726 28.4016V20.041C168.726 18.541 168.357 15.959 165.701 15.959C163.045 15.959 162.11 18.2951 162.11 20.459V28.4016C162.11 28.5984 161.938 28.7705 161.741 28.7705H158.176C157.954 28.7459 157.807 28.5984 157.807 28.4016Z"
              fill="white"
            />
            <path
              d="M176.079 28.4016V13.082C176.079 12.8852 176.251 12.7131 176.447 12.7131H180.013C180.21 12.7131 180.382 12.8852 180.382 13.082V15.1721C181.218 13.7213 182.792 12.3934 185.473 12.3934C188.227 12.3934 191.301 13.7459 191.301 19.9672V28.4016C191.301 28.5984 191.129 28.7705 190.932 28.7705H187.366C187.17 28.7705 186.997 28.5984 186.997 28.4016V20.041C186.997 18.541 186.629 15.959 183.973 15.959C181.317 15.959 180.382 18.2951 180.382 20.459V28.4016C180.382 28.5984 180.21 28.7705 180.013 28.7705H176.447C176.251 28.7459 176.079 28.5984 176.079 28.4016Z"
              fill="white"
            />
            <path
              d="M213.532 12.7131H217C217.196 12.7131 217.368 12.8852 217.368 13.082V16.2541C218.057 13.1066 220.762 12.0246 222.729 12.4918C222.902 12.5164 223 12.6639 223 12.8361V16.3279C223 16.5492 222.803 16.7213 222.557 16.6721C218.918 15.959 217.442 17.9262 217.442 20.6803C217.442 22.6475 217.442 27.0984 217.442 28.4016C217.442 28.5984 217.27 28.7705 217.073 28.7705H213.508C213.311 28.7705 213.139 28.5984 213.139 28.4016V13.082C213.163 12.8852 213.335 12.7131 213.532 12.7131Z"
              fill="white"
            />
            <path
              d="M67.1116 16.2049C66.005 15.8361 65.4148 15.6148 64.5295 15.3197C63.6196 15 62.0703 14.1148 62.0703 12.6885C62.0703 11.2623 63.054 10.3525 65.0459 10.3525C66.8657 10.3525 67.874 11.2377 68.538 12.6639C68.6363 12.8607 68.8577 12.9344 69.0544 12.8361L71.9808 11.1393C72.1284 11.041 72.2022 10.8443 72.1284 10.6721C71.2431 8.72951 69.1774 6.39344 65.0951 6.39344C60.5456 6.39344 57.6929 9.09836 57.6929 12.6639C57.6929 16.2049 60.0292 18.0492 63.2015 19.1311C64.3573 19.5246 64.8246 19.6967 65.8083 20.0164C67.6773 20.6557 68.538 21.4918 68.538 22.6475C68.538 23.8033 67.8002 25.1803 64.8984 25.1803C62.2424 25.1803 61.3079 23.7787 60.7669 22.4508C60.6931 22.2541 60.4472 22.1557 60.2505 22.2787L57.1765 24.0492C57.0289 24.1475 56.9552 24.3197 57.0289 24.4918C58.2094 27.3934 61.4309 29.1148 65.0459 29.1148C69.3003 29.1148 72.8907 26.9508 72.8907 22.5738C72.8907 18.1967 68.2183 16.5738 67.1116 16.2049Z"
              fill="white"
            />
            <path
              d="M90.5969 24.5656C90.4986 24.3934 90.2527 24.3197 90.0805 24.4426C88.7034 25.4508 87.0803 25.4754 85.8507 23.582C84.8178 21.9836 83.5636 20.0164 83.5636 20.0164L88.9739 13.3033C89.1706 13.0574 88.9985 12.7131 88.7034 12.7131H84.4736C84.3506 12.7131 84.2522 12.7623 84.1784 12.8607L79.506 19.2787V6.36885C79.506 6.17213 79.3338 6 79.1371 6H75.5713C75.3745 6 75.2024 6.17213 75.2024 6.36885V28.4016C75.2024 28.5984 75.3745 28.7705 75.5713 28.7705H79.1617C79.3584 28.7705 79.5306 28.5984 79.5306 28.4016V21.2459C79.5306 21.2459 81.916 25.0082 82.8259 26.4098C84.0555 28.3279 85.7769 29.1148 87.6459 29.1148C89.4165 29.1148 90.5478 28.6475 91.9003 27.3197C92.0233 27.1967 92.0479 27.0246 91.9741 26.877L90.5969 24.5656Z"
              fill="white"
            />
            <path
              d="M116.197 19.5C115.066 19.082 114.672 18.9344 113.812 18.6148C112.951 18.2951 112.189 17.8279 112.189 17.1393C112.189 16.4508 112.828 15.6885 114.131 15.6885C115.263 15.6885 115.951 16.1311 116.345 16.918C116.443 17.1148 116.664 17.1639 116.837 17.0656L119.345 15.6148C119.517 15.5164 119.566 15.2951 119.468 15.123C118.779 13.918 117.427 12.3934 114.156 12.3934C110.148 12.3934 108.082 14.7049 108.082 17.2623C108.082 19.8197 110.172 21.0246 112.09 21.7869C114.254 22.6721 114.402 22.7213 114.672 22.8197C115.336 23.0902 116.148 23.5082 116.148 24.2705C116.148 25.0328 115.263 25.7213 113.91 25.7213C112.656 25.7213 111.23 25.2049 110.639 23.7541C110.566 23.5574 110.32 23.459 110.123 23.582L107.59 25.0328C107.442 25.1311 107.369 25.3033 107.442 25.4754C108.18 27.3934 110.369 29.0902 113.91 29.0902C117.746 29.0902 120.304 27.0492 120.304 24.1721C120.304 21.2951 118.337 20.2869 116.197 19.5Z"
              fill="white"
            />
            <path
              d="M130.608 16.2049C132.207 16.2049 133.387 16.6967 134.494 17.4836C134.666 17.6066 134.912 17.5574 135.01 17.3852L136.486 14.8279C136.584 14.6557 136.535 14.459 136.363 14.3607C134.838 13.3279 132.944 12.3689 130.387 12.3689C127.854 12.3689 125.518 13.0574 123.845 14.7541C122.173 16.4262 121.411 18.418 121.411 20.7787C121.411 23.6311 122.591 25.4508 123.87 26.7295C125.149 28.0082 127.46 29.0902 130.411 29.0902C133.067 29.0902 135.035 27.9836 136.387 27.0984C136.535 27 136.584 26.7787 136.486 26.6311L135.035 24.0984C134.936 23.9262 134.69 23.8525 134.518 23.9754C133.559 24.6639 132.182 25.2541 130.633 25.2541C128.887 25.2541 125.714 24.2705 125.714 20.7295C125.714 17.1885 128.862 16.2049 130.608 16.2049Z"
              fill="white"
            />
            <path
              d="M202.958 25.5492C201.531 25.5492 200.179 25.2049 199.195 24.418C198.211 23.6311 197.744 22.8197 197.744 21.8607H210.483C210.679 21.8607 210.852 21.6885 210.852 21.4918C210.778 17.7049 209.548 15.9344 208.368 14.7295C207.138 13.5 205.048 12.3689 202.294 12.3689C199.539 12.3689 197.424 13.377 195.973 14.8033C194.547 16.2295 193.465 18.0492 193.465 20.8033C193.465 23.5574 194.572 25.377 195.924 26.7295C197.277 28.082 199.49 29.0902 202.662 29.0902C205.663 29.0902 208.196 28.0328 210.015 25.9426C210.163 25.7705 210.114 25.5 209.917 25.4016L207.384 23.9262C207.261 23.8525 207.089 23.8525 206.966 23.9754C205.712 25.1557 204.113 25.5492 202.958 25.5492ZM202.269 15.8115C204.31 15.8115 206.179 17.041 206.474 19.2541H197.892C198.457 16.8689 200.228 15.8115 202.269 15.8115Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_8958_12424">
              <rect width="223" height="36" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <h1 className="strapline">Let&apos;s change how the world travels</h1>

      <div className="arrow-container">
        <svg
          role="presentation"
          viewBox="0 0 448 512"
          className="scroll-down-arrow bounce"
        >
          <path
            role="presentation"
            d="M207,381.5L12.7,187.1c-9.4-9.4-9.4-24.6,0-33.9l22.7-22.7c9.4-9.4,24.5-9.4,33.9,0l154.7,154l154.7-154 c9.4-9.3,24.5-9.3,33.9,0l22.7,22.7c9.4,9.4,9.4,24.6,0,33.9L241,381.5C231.6,390.8,216.4,390.8,207,381.5L207,381.5z"
          />
        </svg>
      </div>
    </div>

    <div className="real-deal-container section-padding">
      <div className="row">
        <div className="real-deal-text">
          <p className="sub-heading">
            Skyscanner is The Real Deal. Our people are The Real Deal. It&apos;s
            why we continue to thrive, together.
          </p>
          <p className="sub-heading">
            If you want to join us in embracing software at scale in an
            environment that still feels small enough for everyone to make an
            impact, read on. Here&apos;s everything you need to know about life
            at Skyscanner and how to prepare for your interview.
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default PrepPackHero;
