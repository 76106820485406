import BpkLargeEditIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/edit';
import BpkLargeEducationIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/education';
import BpkLargeFlightIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/flight';
import BpkLargeLanguageIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/language';
import BpkLargeSettingsIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/settings';
import BpkLargeTrendingIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/trend';

import Card from '../../components/Card';
import JobsAppHero from '../../components/JobsAppHero';
import { useGetJobs } from '../../hooks/useGetJobs';

const TeamsPage = () => {
  const teams = [
    {
      title: 'Engineering',
      code: 'ENG',
      icon: <BpkLargeSettingsIcon className="card__icon" />,
    },
    {
      title: 'Marketing',
      code: 'MKT',
      icon: <BpkLargeLanguageIcon className="card__icon" />,
    },
    {
      title: 'Product',
      code: 'PRD',
      icon: <BpkLargeFlightIcon className="card__icon" />,
    },
    {
      title: 'Business',
      code: 'BUS',
      icon: <BpkLargeTrendingIcon className="card__icon" />,
    },
    {
      title: 'Design, Research & Copywriting',
      code: 'DSN',
      icon: <BpkLargeEditIcon className="card__icon" />,
      greenhouseBusinessArea: 'Design',
      path: 'design',
    },
    {
      title: 'Graduates & Interns',
      code: 'GRD',
      icon: <BpkLargeEducationIcon className="card__icon" />,
      greenhouseBusinessArea: 'Graduate',
      path: 'earlycareers',
    },
  ];

  const { jobs, loading } = useGetJobs();

  const getJobCountForTeam = (teamTitle: string) => {
    const teamJobs = jobs.filter((job) => {
      const jobMeta = job.metadata.find(
        (meta) => meta.name === 'Business Area',
      );
      const jobDepartment = jobMeta?.value;

      return jobDepartment && teamTitle.includes(jobDepartment);
    });
    return teamJobs.length;
  };

  return (
    <>
      <JobsAppHero bannerType="teams" />
      <div className="grid__container">
        <div className="grid__row">
          <div className="grid__row">
            <div className="grid__column--12">
              <section className="grid__container">
                <h1>Our Teams</h1>
                <p className="teams-text">
                  Our teams bring together people from a wide range of areas and
                  professional backgrounds, from a variety of educations,
                  skills, strengths and cultures. That’s what makes Skyscanner
                  the Real Deal – one of the best, happiest and most engaging
                  environments to grow your career in.
                </p>
                <div>
                  {' '}
                  <hr aria-hidden="true" />{' '}
                </div>
              </section>
              <div className="grid__container">
                <ul className="cards-list columns">
                  {teams.map((team) => (
                    <Card
                      key={`${team.code}-${team.title}`}
                      title={team.title}
                      code={team.code}
                      path={team.path}
                      variation="teams"
                      count={
                        loading ? undefined : getJobCountForTeam(team.title)
                      }
                      icon={team.icon}
                    />
                  ))}
                  <li className="card-container">
                    <div className="card card--dummy" />
                  </li>
                  <li className="card-container">
                    <div className="card card--dummy" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamsPage;
