import BpkModal from '@skyscanner/backpack-web/bpk-component-modal';

import {
  CultureSection,
  DepthOfExpertiseSection,
  InterviewNoteSection,
  SystemDesignSection,
} from './InterviewProcessModalSections';

import STYLES from '../../styles/InterviewProcessModal.module.scss';

export const ModalType = {
  Closed: 'closed',
  Graduate: 'graduate',
  Engineer1: 'engineer1',
  EngineerFinal: 'engineerFinal',
  DataScientist1: 'dataScientist1',
  DataScientistFinal: 'dataScientistFinal',
  PrincipalEngineer1: 'principalEngineer1',
  PrincipalEngineerFinal: 'principalEngineerFinal',
  EngineeringManagerFinal: 'engineerManagerFinal',
};

export type ModalTypeKeys = (typeof ModalType)[keyof typeof ModalType];

const getTitle = (type: string) => {
  if (type.includes('1')) {
    return '1st Stage Interview';
  }
  return 'Final Stage Interview';
};

export const InterviewProcessModal = ({
  handleSetModalType,
  modalType,
}: {
  modalType: ModalTypeKeys;
  handleSetModalType: (newModalType: ModalTypeKeys) => void;
}) => (
  <BpkModal
    id="interviewPrep"
    aria-label="Interview Preparation modal"
    title={getTitle(modalType)}
    getApplicationElement={() => document.getElementById('pagewrap')}
    isOpen={modalType !== ModalType.Closed}
    onClose={() => handleSetModalType(ModalType.Closed)}
    wide
    closeLabel="close"
    className={STYLES.modal}
  >
    {ModalTypeToContent[modalType]}
  </BpkModal>
);

const GraduateDiscoveryDayContent = () => (
  <div>
    <h3>Discovery Day</h3>
    <h4>Group exercise - 90 mins</h4>
    <p>
      You&rsquo;ll be split into groups of four and given a typical scenario
      that our staff may have to deal at Skyscanner. You&rsquo;ll then work
      through the exercise with your group and present your ideas and solutions
      to your peers in a 5-10-minute presentation including a Q&A.
    </p>
    <h4>Culture interview - 45 mins</h4>
    <p>
      A non-technical conversation focusing on real examples, looking
      specifically at how much you match with Skyscanner&rsquo;s culture. This
      is a chance for us to get to know more about who you are. It&rsquo;s a bit
      like your initial Zoom interview but this time we&rsquo;ll dig a little
      deeper into your answers. It&rsquo;s also a chance for you to learn a bit
      more about the culture here at Skyscanner.
    </p>
    <p>
      Values - Please have a read over
      <a
        href="https://www.skyscanner.net/jobs/life"
        target="_blank"
        rel="noreferrer"
      >
        {` `}our values
      </a>
      .
    </p>
    <p>
      Motivation - The interviewer would love to hear more about your motivation
      and why you want to join Skyscanner.
    </p>

    <p>
      Tip - Use the
      <a
        href="https://en.wikipedia.org/wiki/Situation,_task,_action,_result"
        target="_blank"
        rel="noreferrer"
      >
        {` `}STAR format{` `}
      </a>
      if you can.
    </p>
    <h4>Technical interview - 45 mins</h4>
    <p>
      You will be given a typical problem that our staff at Skyscanner might
      face. You’ll then work through the problem with your interviewer, using
      the whiteboard, talking them through your thought process to get to a
      solution.
    </p>
    <p>
      As much as you can, add context around the ‘why’ rather than just the how.
      Give as much depth of knowledge and context on the problem as possible.
    </p>
    <hr />
    <InterviewNoteSection />
  </div>
);

const SoftwareEngineerFirstContent = () => (
  <div>
    <h3>First stage:</h3>
    <DepthOfExpertiseSection />
    <hr />
    <InterviewNoteSection />
  </div>
);

const SoftwareEngineerFinalContent = () => (
  <div>
    <h3>Final stage:</h3>
    <CultureSection />
    <SystemDesignSection />
    <hr />
    <InterviewNoteSection />
  </div>
);

const DataScientist1Content = () => (
  <div>
    <h3>First stage:</h3>
    <h4>Project discussion</h4>
    <p>
      You’ll need to ask questions to clarify the problem, talk through your
      initial approach to form a high-level solution, and then get into the
      details of how such a solution could be rolled out.
    </p>
    <hr />
    <InterviewNoteSection />
  </div>
);

const DataScientistFinalContent = () => (
  <div>
    <h3>Final stage:</h3>
    <CultureSection />
    <h4>Technical Interview</h4>
    <p>
      Your recruiter will provide some more information on how to prepare for
      the technical interview.
    </p>
    <hr />
    <InterviewNoteSection />
  </div>
);

const PrincipalEngineer1Content = () => (
  <div>
    <h3>First stage:</h3>
    <DepthOfExpertiseSection />
    <hr />
    <InterviewNoteSection />
  </div>
);

const PrincipalEngineerFinalContent = () => (
  <div>
    <h3>Final stage:</h3>
    <CultureSection upperLevelPosition />
    <SystemDesignSection double />
    <hr />
    <InterviewNoteSection />
  </div>
);

const EngineeringManagerFinalContent = () => (
  <div>
    <h3>Final stage:</h3>
    <CultureSection upperLevelPosition />
    <SystemDesignSection />
    <h4>Team Delivery and Development</h4>
    <p>
      The Delivery and Team Development interview begins with a short case
      study, where you and the interviewer will work through two sections for
      about 10-15 mins each. The opening situation is given to you before the
      interview, with extra information layered on during the interview.
    </p>
    <p>
      We&lsquo;d like you to take the interviewer through the actions, tools and
      frameworks you would apply and why. They will try and help you stay at the
      right level; we don&lsquo;t want to focus too much on one particular
      aspect to the detriment of thinking about the whole scenario.
    </p>
    <hr />
    <InterviewNoteSection />
  </div>
);

const ModalTypeToContent = {
  [ModalType.Graduate]: <GraduateDiscoveryDayContent />,
  [ModalType.Engineer1]: <SoftwareEngineerFirstContent />,
  [ModalType.EngineerFinal]: <SoftwareEngineerFinalContent />,
  [ModalType.DataScientist1]: <DataScientist1Content />,
  [ModalType.DataScientistFinal]: <DataScientistFinalContent />,
  [ModalType.PrincipalEngineer1]: <PrincipalEngineer1Content />,
  [ModalType.PrincipalEngineerFinal]: <PrincipalEngineerFinalContent />,
  [ModalType.EngineeringManagerFinal]: <EngineeringManagerFinalContent />,
};
