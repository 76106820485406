import BpkAircraftIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/aircraft';
import BpkCityIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/city';
import BpkHappyFaceIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/face--happy';

import JobsAppHero from '../components/JobsAppHero';

const LifePage = () => (
  <>
    <JobsAppHero hideHero={false} bannerType="life" hideHeroMobile={false} />
    <div className="grid__container">
      <div className="grid__row">
        <div className="grid__column--padded grid__column--tablet-12">
          <div className="skyscanner-quote">
            <h1 id="life-section1-header">Skyscanner. The Real Deal.</h1>
            <p id="life-section1-body">
              We’re a worldwide team of 1,500 talented, passionate individuals
              obsessed with making the experience of planning and booking travel
              better for everyone. We’re proud of the positive culture and
              community we’ve created as a place where everyone can grow,
              alongside providing competitive benefits, awesome social events
              and travel perks.
            </p>
          </div>
        </div>
      </div>
      <hr />
    </div>

    <section className="grid__container">
      <div className="video-container--landscape content-block">
        <video
          className="video"
          controls
          src="https://content.skyscnr.com/m/74e6440fcdd95b69/original/Jobs-site-Launch-video.mp4"
          width="100%"
        >
          <track
            src="../transcripts/Skyscanner-Launch-subs-V0.6-en-US.vtt"
            kind="captions"
            label="English"
          />
        </video>
      </div>
      <hr aria-hidden="true" />
    </section>

    <div className="grid__container">
      <div className="grid__row content-block">
        <div className="grid__column--4 grid__column--tablet-12">
          <BpkAircraftIcon className="life-icon" />
          <h2 id="life-section2-col1-header">More to explore</h2>
          <div id="life-section2-col1-body">
            <p>
              We love to travel. So we encourage our people to explore and see
              the world. We offer generous time off, extended leave
              opportunities and reimbursement on select trips.
            </p>
            <p>
              If you fancy a break from your main office, or home office, you
              can work anywhere in the world for up to four weeks per year. You
              can also spend time working from another incredible Skyscanner
              location and work alongside the people you’ve been speaking with
              on Zoom in real life.
            </p>
          </div>
        </div>
        <div className="grid__column--4 grid__column--tablet-12">
          <BpkHappyFaceIcon className="life-icon" />
          <h2 id="life-section2-col2-header">Happy matters</h2>
          <div id="life-section2-col2-body">
            <p>
              Happiness inside and outside work is important and we want you to
              bring your whole self to work. We take the mental and physical
              wellbeing of our teams very seriously.
            </p>
            <p>
              As well as day-to-day office perks (like tasty snacks, great
              coffee and cold drinks), team lunches and fun themed social
              events, we offer discounts on gym membership, access to mental
              health apps, flexible working arrangements and much more.
            </p>
          </div>
        </div>
        <div className="grid__column--4 grid__column--tablet-12">
          <BpkCityIcon className="life-icon" />
          <h2 id="life-section2-col3-header">Perks, your way</h2>
          <div id="life-section2-col3-body">
            <p>
              Everyone is different, so it makes sense for our people to choose
              the perks and benefits that most appeal to them.
            </p>
            <p>
              In addition to travel, healthcare, wellbeing and bonus schemes,
              you can take your pick from a range of other perks. Fancy learning
              a new skill? Check out our free courses. Want some money towards
              setting up your home office? We’ve got you covered.
            </p>
          </div>
        </div>
      </div>
      <hr />
    </div>
    <div className="grid__container">
      <div className="grid__row content-block">
        <div className="grid__column--12">
          <h2 id="life-section3-header">Our values</h2>
          <div id="life-section4-body">
            <p>
              Travel means discovering new perspectives and cultures. As a
              travel tech company, we know Skyscanner is strongest when our team
              is both inclusive and diverse. We’re committed to taking that
              journey together, recognising and challenging everyday biases,
              removing obstacles to encourage inclusion and ensuring that all
              our people can thrive as their authentic selves.
            </p>
            <p>
              Our values reflect this &mdash; we’re big on collaboration,
              treating others with respect and care, and taking ownership.
              Sounds like something you agree with? Come and join us for the
              journey.
            </p>
          </div>
        </div>
      </div>
      <div className="grid__row value-container">
        <img
          className="value-icon"
          src="https://images.skyscnr.com/sttc/jobssite/img/think-traveller-icon.97407b6afbc626de331f.png"
          alt="think traveller icon"
        />
        <div className="grid__column--9 grid__column--mobile-12">
          <h2 id="life-section4-col1-header">We think traveller first.</h2>
          <p id="index-section1-body">
            We love to travel - this is what unites us. It’s the reason we think
            traveller first, partner second and Skyscanner third.
          </p>
        </div>
      </div>
      <div className="grid__row value-container">
        <img
          className="value-icon"
          src="https://images.skyscnr.com/sttc/jobssite/img/learn-share-grow-icon.0bc6f71e9840a2653f3e.png"
          alt="learn, share, grow icon"
        />
        <div className="grid__column--9 grid__column--mobile-12">
          <h2 id="life-section4-col2-header">We learn, share and grow.</h2>
          <p id="index-section2-body">
            Our people’s diversity is our superpower. Different skills,
            experiences and backgrounds help us develop as individuals, as well
            as a business and industry.
          </p>
        </div>
      </div>
      <div className="grid__row value-container">
        <img
          className="value-icon"
          src="https://images.skyscnr.com/sttc/jobssite/img/we-care-always-icon.a21400eb4305feb8c4be.png"
          alt="learn, share, grow icon"
        />
        <div className="grid__column--9 grid__column--mobile-12">
          <h2 id="life-section4-col3-header">We care, always.</h2>
          <p id="index-section3-body">
            We treat each other with empathy and respect because that’s how
            we’ll achieve great things together.
          </p>
        </div>
      </div>
      <div className="grid__row value-container">
        <img
          className="value-icon"
          src="https://images.skyscnr.com/sttc/jobssite/img/make-it-simple-icon.66a8f11bfc42f5154cb0.png"
          alt="think traveller icon"
        />
        <div className="grid__column--9 grid__column--mobile-12">
          <h2 id="life-section4-col4-header">We make it simple.</h2>
          <p id="index-section4-body">
            We’ve done this since day one. We look for simple and smart ways to
            work together and build things that will make travel easy and
            accessible for everyone.
          </p>
        </div>
      </div>
      <div className="grid__row value-container">
        <img
          className="value-icon"
          src="https://images.skyscnr.com/sttc/jobssite/img/together-icon.bea9b0f827b4f59efdef.png"
          alt="think traveller icon"
        />
        <div className="grid__column--9 grid__column--mobile-12">
          <h2 id="life-section4-col5-header">We get it done, together.</h2>
          <p id="index-section5-body">
            Our ambition drives us every day. When we see an opportunity to make
            things better, we go after it and get it right, not being afraid to
            learn as we go.
          </p>
        </div>
      </div>
    </div>
    <section className="grid__container">
      <hr />
      <div className="grid__row center-row content-block">
        <div className="grid__column--5 grid__column--tablet-12 video-container--portrait">
          <video
            className="video"
            controls
            src="https://content.skyscnr.com/m/3d0fbf8c545f06d4/original/Skyscanner-Ben-Social-9x16-lockup-subs-V2.mp4"
          >
            <track
              src="../transcripts/Skyscanner-Ben-subs-V2-en-US.vtt"
              kind="captions"
              label="English"
            />
          </video>
        </div>
        <div className="grid__column--7 grid__column--tablet-12">
          <div className="skyscanner-quote">
            <p className="quote">
              “We have a really nice flywheel of learning at Skyscanner.
              It&rsquo;s about giving people the right outcomes to work for and
              watching them absolutely smash it.”
            </p>
            <p className="sub-heading">
              Ben Stewart, Senior Director, Engineering
            </p>
          </div>
        </div>
      </div>
    </section>
  </>
);

export default LifePage;
