import { useState } from 'react';

import JobList from '../../components/JobList';

const DesignPage = () => {
  const [jobCount, setJobCount] = useState(0);

  return (
    <div>
      <section className="grid__container">
        <div className="grid__row content-block">
          <div className="grid__column--12 grid__column--mobile-12">
            <p className="page-sub-title">Put your creativity to work</p>
            <h1 className="teams-header">Design, Research &amp; Copywriting</h1>
            <a
              href="#job-list-section"
              className={`button${jobCount === 0 ? ' button--disabled' : ''}`}
              id="explore-jobs-button"
            >
              Explore jobs
            </a>
            <div className=" content-block">
              <p id="teams-engineering-body">
                Split across three main areas &mdash; Brand Marketing, Product
                Design and User Research &mdash; we’re focussed on creating
                meaningful, traveller-first communications and crafting design
                and user experiences that attract new users and delight our
                existing ones.
                <br />
                <br />
                In Brand Marketing we work on campaigns of all sizes, from TV
                ads and outdoor to destination partnership promotions for the
                world’s biggest tourist boards &mdash; all in service of helping
                steer the brand into exciting new territory.
                <br />
                <br />
                In Product Design, our team is committed to creating streamlined
                and effective user experiences across our app and website. We
                focus on understanding our travellers, defining the problems
                they face and designing user journeys that answer their needs.
                <br />
                <br />
                Our Research team’s mission is to enable all teams at Skyscanner
                to better understand travellers so we can shape products,
                marketing and services travellers globally love.
                <br />
                <br />
                Skyscanner is The Real Deal. Our people are The Real Deal. It’s
                why we continue to thrive, together. Our teams are set up to
                make autonomous decisions about how best to work, so you’ll
                enjoy real ownership without being micromanaged. Some will
                choose to work in sprints, while others will focus on campaigns
                over longer periods of time.
                <br />
                <br />
                No matter your specialism or preferences, you’ll find yourself
                in the great company of friendly, passionate, collaborative,
                high-performing individuals.
              </p>
            </div>
          </div>
        </div>
        <hr aria-hidden="true" />
      </section>
      <section className="grid__container">
        <div className="grid__row center-row content-block">
          <div className="grid__column--5 grid__column--tablet-12 photo-container">
            <img
              className="photo"
              alt="Kim Durbridge, Senior Content Designer &amp; Writer"
              src="https://images.skyscnr.com/sttc/jobssite/img/kim.94cfe48f7d90d3bf5972.jpg"
            />
          </div>
          <div className="grid__column--7 grid__column--tablet-12">
            <div className="skyscanner-quote">
              <p className="quote">
                “I get to work with a wide range of talented and knowledgeable
                experts in each field. I have learnt so much from my peers in
                Design at Skyscanner.”
              </p>
              <p className="sub-heading">
                Kim Durbridge, Senior Content Designer &amp; Writer
              </p>
            </div>
          </div>
        </div>
      </section>
      <JobList
        department="Design"
        setJobCountCallback={(count) => setJobCount(count)}
      />
    </div>
  );
};

export default DesignPage;
