import { useState } from 'react';

const JobsAppBanner = () => {
  const [open, setOpen] = useState(false);
  const handleToggle = () => setOpen((prevOpen) => !prevOpen);
  return (
    <div className="banner">
      <button
        className="banner-toggle"
        type="button"
        onClick={handleToggle}
        data-testid="jobs-banner-toggle"
      >
        <div className="banner-toggle__text">
          Read me! Check out our top tips to make sure you are in contact with a
          genuine Skyscanner recruiter.
        </div>
      </button>
      {open && (
        <>
          <div className="banner-content" data-testid="jobs-banner-content">
            <div className="banner-content__text">
              <p>
                We have been made aware that some people have received
                fraudulent messages from accounts impersonating members of our
                recruitment team. We wanted to share some guidance to help you
                identify a genuine message from our team:
              </p>
              <ul>
                <li>
                  All official Skyscanner recruitment emails end with
                  @skyscanner.net - impersonators may use a similar domain so
                  please double-check.
                </li>
                <li>
                  We do not ask for personal details or documents (proof of
                  address, identification documents) in the early stages of the
                  interview process.
                </li>
                <li>
                  Skyscanner will never ask candidates for money at any point
                  during the recruitment process.
                </li>
                <li>
                  If you encounter any suspicious activity or receive messages
                  for someone claiming to be from Skyscanner, we would encourage
                  you to contact{' '}
                  <a href="mailto:help@skyscanner.net">help@skyscanner.net</a>{' '}
                  to verify details.
                </li>
              </ul>
            </div>
          </div>
          <hr />
        </>
      )}
    </div>
  );
};

export default JobsAppBanner;
